import React, { useEffect } from "react";
import Banner from "./Components/Banner";
import Pricing from "./Components/Pricing";
import GetStarted from "../../Components/GetStarted";
import ClientSay from "../../Components/ClientSay";
import TrustedBy from "../../Components/TrustedBy";
import {
  customerLogos,
  faq_Config,
  sellerLogos,
} from "../../Utils/staticsData";
import { autoScroll } from "../../Utils/helpers";
import FAQ from "../../Components/FAQ";
import FingerTips from "./Components/FingerTips";
import OfficeSpace from "./Components/OfficeSpace";
import "./index.scss";
import EasyProcess from "./Components/EasyProcess";
import OurBlogs from "./Components/OurBlogs";

const Home = () => {
  const href = window.location.href.substring(window.location.href);

  useEffect(() => {
    autoScroll();
  }, [href]);
  return (
    <div>
      <Banner />
      <TrustedBy
        companyLogos={sellerLogos}
        text="Trusted by 12+ Sellers"
        customerLogos={customerLogos}
      />
      <FingerTips />

      <OfficeSpace />
      <EasyProcess />
      <div className="faq-client-say">
        <ClientSay />
        <FAQ faq_Config={faq_Config} />
      </div>

      <Pricing />
      <OurBlogs />
      <GetStarted />
    </div>
  );
};

export default Home;
