import React from "react";
import logo from "../../../Assets/Images/logo.svg";
import NavLink from "../../../Components/NavLinks";
import { navLinks } from "../../../Utils/staticsData";
import { Button } from "antd";
import user from "../../../Assets/Icons/user-alt-1.svg";
import house from "../../../Assets/Icons/house-window.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetSteps } from "../../../Redux/Slices/PlanSlice";

const DeskBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleBook = () => {
    dispatch(resetSteps());
    navigate("/booking");
  };
  return (
    <div className="navbar-container">
      <div className="desk-logo-container">
        {" "}
        <Link to="/">
          <img src={logo} width="150px" alt="logo" />
        </Link>
      </div>

      <div className="nav-links-container">
        {navLinks.map(({ link, label, hasSubContent, noContent }, index) => (
          <NavLink
            label={label}
            link={link}
            index={index}
            hasSubContent={hasSubContent}
            noContent={noContent}
          />
        ))}
      </div>
      <div className="nav-action-container">
        {/* <Button
          className="contained-white nav-btns login-nav-btn"
          icon={<img src={user} alt="user" width="20px" />}
        >
          Login
        </Button> */}
        <Button
          className="contained-primary nav-btns"
          style={{ marginLeft: "12px" }}
          icon={<img src={house} alt="wa" width="20px" />}
          onClick={handleBook}
        >
          Book Service
        </Button>
      </div>
    </div>
  );
};

export default DeskBar;
