import React, { useEffect, useState } from "react";
import "./index.scss";
import Title from "antd/es/typography/Title";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
// import { faq_Config } from "../../Utils/staticsData";
import parse from "html-react-parser";
import { Card, Button, Row, Col, Typography } from "antd";
import downArrow from "../../Assets/Icons/dwn-grey.svg";

const FAQ = ({ faq_Config }) => {
  const [showTextArray, setShowTextArray] = useState(null); //  true means show only preview
  const toggleTextVisibility = (index) => {
    if (showTextArray === index) {
      setShowTextArray(null);
    } else setShowTextArray(index);
  };
  useEffect(() => {
    setShowTextArray(null);
  }, [faq_Config]);

  // useEffect(() => {
  //   setFaqToRender(minFaq);
  // }, [faq_Config]);
  const midIndex = Math.ceil(faq_Config.length / 2);
  // const showMoreTorender = () => {
  //   if (faq_Config.length > 12) {
  //     if (isShowMore) {
  //       return (
  //         <Button
  //           type="text"
  //           className="faq-load-more d-flex row-reverse align-center"
  //           icon={
  //             <img src={downArrow} alt="load-more" className="load-less-icon" />
  //           }
  //           onClick={() => toggleShow("less")}
  //         >
  //           Load Less
  //         </Button>
  //       );
  //     } else
  //       return (
  //         <Button
  //           type="text"
  //           className="faq-load-more d-flex row-reverse align-center"
  //           icon={<img src={downArrow} alt="load-more" />}
  //           onClick={() => toggleShow("more")}
  //         >
  //           Load more
  //         </Button>
  //       );
  //   }
  // };
  return (
    <div className="faq-container">
      <div className="faq-wrapper">
        <Title level={2} className="font-400 faq-heading">
          {" "}
          Frequently Asked Questions
        </Title>
        <div className="faq-card-wrapper">
          <div className="faq-card-wrapper">
            <div className="faq-column">
              {faq_Config.slice(0, midIndex).map((item) => (
                // <Col xs={24} md={12}>
                <Card
                  className={
                    showTextArray !== item?.id
                      ? "faq-card "
                      : "faq-card faq-open faq-primary-border"
                  }
                  key={item?.id}
                  onClick={() => toggleTextVisibility(item?.id)}
                >
                  <div className="content">
                    <Typography className="faq-card-heading ">
                      {item?.heading}
                    </Typography>
                    {showTextArray === item?.id && (
                      <div className="faq-card-text"> {parse(item?.text)}</div>
                    )}
                  </div>
                  <div className="icon">
                    <p
                      className={`${showTextArray !== item?.id ? "" : "hide"}`}
                    >
                      {showTextArray !== item?.id ? (
                        <PlusOutlined />
                      ) : (
                        <MinusOutlined />
                      )}
                    </p>
                  </div>
                </Card>
                // </Col>
              ))}
            </div>
            <div className="faq-column">
              {faq_Config.slice(midIndex).map((item) => (
                // <Col xs={24} md={12}>
                <Card
                  className={
                    showTextArray !== item?.id
                      ? "faq-card "
                      : "faq-card faq-open faq-primary-border"
                  }
                  key={item?.id}
                  onClick={() => toggleTextVisibility(item?.id)}
                >
                  <div className="content">
                    <div className="faq-card-heading ">{item?.heading}</div>
                    {showTextArray === item?.id && (
                      <div className="faq-card-text"> {parse(item?.text)}</div>
                    )}
                  </div>
                  <div className="icon">
                    <p
                      className={`${showTextArray !== item?.id ? "" : "hide"}`}
                      style={{ margin: 0 }}
                    >
                      {showTextArray !== item?.id ? (
                        <PlusOutlined />
                      ) : (
                        <MinusOutlined />
                      )}
                    </p>
                  </div>
                </Card>
                // </Col>
              ))}
            </div>
          </div>
        </div>
        {/* {showMoreTorender()} */}
      </div>
    </div>
  );
};

export default FAQ;
