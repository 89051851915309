import { Button, Card, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import React from "react";
import tick from "../../../../Assets/Icons/tick_grey.svg";
import { ReactSVG } from "react-svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedPlan,
  setSelectedPlanNew,
  setSummaryPlanPrice,
} from "../../../../Redux/Slices/PlanSlice";
import { planPriceBasedUponState } from "../../../../Utils/helpers";

const PricingCard = ({ title, description, pointers, price, id, index }) => {
  const { allServices, selectedService, selectedState, services } = useSelector(
    (state) => state.planSlice
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleStarted = () => {
    console.log(
      "tututut",
      allServices[0].plans[index].states[0].price,
      allServices[0]
    );
    const plan = allServices[0].plans[index];
    dispatch(setSelectedPlanNew(plan));
    dispatch(setSummaryPlanPrice(allServices[0].plans[index].states[0].price));
    navigate("/booking", { state: { dontCall: true } });
    // dispatch(
    //   setSummaryPlanPrice(
    //     planPriceBasedUponState(selectedState, selectedService, plan.title)
    //   )
    // );
  };
  return (
    <Card
      style={{ width: "100%", borderRadius: "16px" }}
      className="pricing-card"
    >
      <div className="pricing-padding-container">
        <Title
          level={5}
          className="pricing-card-title  typography-reset-margin text-white"
        >
          {" "}
          {title}
        </Title>
        {/* <Paragraph className="pricing-card-description text-14 font-grey-2 typography-reset-margin">
          {description}
        </Paragraph> */}
        <div className="pricing-card-price text-16">
          <Paragraph className="pricing-card-pricing-complete text-white">
            ₹ <span className={"plan-price"}>{price}/</span>
            <span className="card-month">Month</span>
          </Paragraph>
          {/* <Link to="/booking"> */}{" "}
          <Button
            className="price-card-btn"
            size="large"
            onClick={handleStarted}
          >
            Get Started
          </Button>
          {/* </Link> */}
        </div>
      </div>

      <div className="benefits-container">
        <Title level={5} className="typography-reset-margin text-white">
          {" "}
          Service Benefits
        </Title>
        {pointers.map((pointer) => (
          <div className="d-flex align-center pointer-icon-text-wrapper">
            <ReactSVG
              src={tick}
              alt="tick"
              // width="20px"
              beforeInjection={(svg) => {
                svg.classList.add("pricing-tick");
              }}
            />
            <Paragraph className="pricing-card-pointer text-14  typography-reset-margin text-white">
              {pointer}
            </Paragraph>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default PricingCard;
