import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Col, Grid, Row, Tag, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import {
  locationData,
  officeSpaces,
  vpobServices,
} from "../../../../Utils/staticsData";
import { officeSpaceSayScroll } from "../../../../Utils/helpers";
import arrowRight from "../../../../Assets/Images/thin-arr-rt.svg";
import { fancyUlTextRenderer } from "../../../../Utils/renderer";
import fancyLine from "../../../../Assets/Icons/styled-orange.svg";
import { Link } from "react-router-dom";

const LocationDataSection = ({ icon, data, label, index }) => (
  <div className={`location-data-section-wrapper data-wrapper-${index}`}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src={icon} alt="icon" />
      <div className="location-data-section-rt">
        <Typography className="location-data-section-data">{data}</Typography>
        <Typography className="location-data-section-label">{label}</Typography>
      </div>
    </div>
  </div>
);

const ClientCard = ({ image, location, offices }) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const [displayText, setDisplayText] = useState(xs);
  return (
    <div
      className={
        displayText
          ? "office-image-card-hover office-image-card"
          : "office-image-card"
      }
      style={
        {
          // background: `url(${image})`,
        }
      }
    >
      <img
        src={image}
        alt="ofc-img"
        width="100%"
        className={displayText ? "ofc-img-hover ofc-img" : "ofc-img"}
        onMouseEnter={() => setDisplayText(true)}
        onMouseLeave={() => setDisplayText(false)}
      />
      {(displayText || xs) && (
        <div
          className="image-card-text-container"
          onMouseEnter={() => setDisplayText(true)}
          onMouseLeave={() => setDisplayText(false)}
        >
          <Typography className="image-card-text-location">
            {location}
          </Typography>
          <Typography className="image-card-text-office-space-text">
            {offices} OFFICE SPACES
          </Typography>
        </div>
      )}
    </div>
  );
};

const ClientSay = () => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  useEffect(() => {
    console.log("xs", xs);
    if (!xs) {
      window.addEventListener("scroll", officeSpaceSayScroll);

      return () => {
        window.removeEventListener("scroll", officeSpaceSayScroll);
      };
    }
  }, [xs]); // Empty dependency array ensures that the effect runs once (like componentDidMount)

  return (
    <div className="office-space-container">
      <div className="client-say-cards-container">
        <div id="office-space-left" className="office-space-left-container">
          <Title level={2} className="office-space-heading  bold">
            Finest office spaces in over{" "}
            {fancyUlTextRenderer(fancyLine, "bold", " 30 locations", "#D77D13")}
          </Title>
          <Paragraph className="office-space-description font-grey-2 ">
            Discover the finest office spaces available in over 30 prime
            locations, tailored to meet your business needs. Elevate your work
            environment with premium amenities and exceptional services.
          </Paragraph>
          <div className="office-location-tags-container">
            {vpobServices.map(
              ({ label, description, stateId, faq_Config, link, name }) => (
                <Link
                  to={link}
                  state={{
                    label,
                    faq: JSON.stringify(faq_Config),
                    description,
                    name,
                    stateId,
                  }}
                >
                  <Tag className="white-tag">
                    <Typography>{label}</Typography>
                  </Tag>
                </Link>
              )
            )}
            <Tag className={"white-tag font-grey-2"}>... and growing more</Tag>
            {/* {officeLcoations.map((data) => (
              <Tag
                className={
                  data === "...coming to yours soon"
                    ? "white-tag font-grey-2"
                    : "white-tag"
                }
              >
                {data}
              </Tag>
            ))} */}
          </div>
          <Link to="/booking">
            <Button
              className="contained-primary   finest-ofc-cta"
              icon={<img src={arrowRight} width="100%" alt="banner-img" />}
              size="sm"
            >
              Get an office
            </Button>
          </Link>
          <Row className="location-data-container">
            {locationData.map((data, index) => (
              <Col xs={12}>
                <LocationDataSection {...data} index={index} />
              </Col>
            ))}
          </Row>
        </div>

        <div className="office-space-right-container">
          {xs ? (
            <Swiper
              direction="horizontal"
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
              slidesPerView={1}
              spaceBetween={10}
              freeMode={true}
            >
              {officeSpaces.map((data) => (
                <SwiperSlide>
                  <ClientCard {...data} />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            officeSpaces.map((data) => <ClientCard {...data} />)
          )}
        </div>
        {/* <div className="client-say-cards-container-right">
          {clientSayings.map((data) => (
            <ClientCard {...data} />
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default ClientSay;
