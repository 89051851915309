import React from "react";
import "./index.scss";
import Title from "antd/es/typography/Title";
import ServiceCard from "./ServiceCard";
import { Col, Row } from "antd";
import { fixedDeskAdvantage } from "../../../Utils/staticsData";
import fixTable from "../../../Assets/Images/fix-table.svg";
import "./index.scss";

const FixedDeskServices = () => {
  return (
    <>
      <div className="gst-service-conatiner">
        <Title level={2} className="gst-services-heading ">
          Fixed Desk Advantages
        </Title>
        <Row gutter={[12, 12]} className="vpob-service-row">
          {fixedDeskAdvantage.map((advantage) => (
            <Col xs={24} md={8}>
              <ServiceCard {...advantage}></ServiceCard>
            </Col>
          ))}
        </Row>
      </div>
      <div className="fixed-desk-table-container">
        {/* <Title level={2} className="gst-services-heading ">
          Our Reasonable Pricing
        </Title> */}
        <div className="fixed-desk-table-wrapper">
          <img
            src={fixTable}
            className="fixed-desk-table"
            alt="fixed-desk-table"
          />
        </div>
      </div>
    </>
  );
};

export default FixedDeskServices;
