import Title from "antd/es/typography/Title";
import React from "react";
import {
  customerLogos,
  sellerLogos,
  vpobadvantageText,
  vpobServices,
} from "../../../Utils/staticsData";
import { Col, Grid, Row, Typography } from "antd";
import "./index.scss";
import ServiceCard from "./ServiceCard";
import advantImg from "../../../Assets/Images/advant-left.png";
import TrustedBy from "../../TrustedBy";

const AdvantageText = ({ label, text }) => (
  <div className="advantage-text-container">
    <Typography className="vpob-advantage-heading">{label}</Typography>
    <Typography className="vpob-advantage-text">{text}</Typography>
  </div>
);
const VPOBService = () => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  return (
    <>
      <div className="vpob-service-container">
        <Title level={2} className="gst-services-heading ">
          Virtual Office and APOB Services
        </Title>
        <Row gutter={[12, 12]} className="vpob-service-row">
          {vpobServices.map((service) => (
            <Col xs={24} md={8}>
              <ServiceCard {...service}></ServiceCard>
            </Col>
          ))}
        </Row>
      </div>

      <div className="vpob-advantage-container  ">
        <Title level={2} className="gst-services-advantage-heading ">
          Advantages of VPOB Virtual Principle Place of Business
        </Title>
        {/* <div className="advantages-data-image-container">
          <img src={dataImage} alt="data-img" />
        </div> */}
        <div className="vpob-data-container">
          <div className="vpob-advantages-left">
            <img src={advantImg} alt="advant" className="vpob-advantage-img" />
          </div>
          <div className="vpob-advantages-right">
            {vpobadvantageText.map((data) => (
              <AdvantageText {...data} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default VPOBService;
