import React from "react";
import "./index.scss";
import { Link, useLocation } from "react-router-dom";
import { bannerChecker, bannerImageChecker } from "../../../../Utils/helpers";
import { Button } from "antd";
import { faq_Config } from "../../../../Utils/staticsData";
import arrowRight from "../../../../Assets/Images/thin-arr-rt.svg";

const Banner = () => {
  const { pathname } = useLocation();
  return (
    <div className="internal-page-banner">
      <div className="internal-page-banner-left">
        {bannerChecker(pathname)}
        <Link
          to="/booking"
          state={{ faq: JSON.stringify(faq_Config), shouldReset: true }}
          className="internal-page-banner-link"
        >
          <Button
            className="contained-primary internal-page-banner-cta"
            style={{ fontSize: "18px" }}
            icon={<img src={arrowRight} width="24px" alt="banner-img" />}
          >
            Start your business
          </Button>
        </Link>
      </div>
      <div className="internal-page-banner-right">
        <img src={bannerImageChecker(pathname)} alt="banner-1" />
      </div>
    </div>
  );
};

export default Banner;
