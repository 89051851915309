import React, { useEffect } from "react";
import "./index.scss";
import { Card, Grid } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
// import { cn } from "@/lib/utils";
// import Marquee from "@/components/magicui/marquee";
import Marquee from "react-marquee-slider";

import { clientSayings, clientSayings2 } from "../../Utils/staticsData";

const ClientCard = ({ description, image, name, designation }) => (
  <Card className="client-saying-card">
    <Paragraph className="saying-description text-black">
      {description}
    </Paragraph>
    <div className="client-card-footer">
      <img src={image} className="client-card-avatar" alt="avatar" />
      <div>
        <Paragraph className="bold  typography-reset-margin  text-black">
          {name}
        </Paragraph>
        <Paragraph className="client-designation text-grey-4   typography-reset-margin font-grey-2">
          {designation}
        </Paragraph>
      </div>
    </div>
  </Card>
);
const ClientSay = () => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  return (
    <div className="client-say-container">
      <Title level={2} className="client-say-heading   text-black ">
        What our <span className="text-primary bold">Clients</span> say about us
      </Title>
      <div className="client-say-cards-container">
        {/* <Paragraph className="client-say-description text-grey-4 ">
            Discover the heartbeat of our business through the words of those we
            serve. Here at Easyworks, client satisfaction is at the core of
            everything we do. But don't just take our word for it – hear
            directly from our valued clients about their experiences working
            with us.
          </Paragraph> */}

        <div className="client-say-cards-right-container">
          {!xs && (
            <Marquee velocity={xs ? 3 : 14}>
              {clientSayings.map((data) => (
                <ClientCard {...data} />
              ))}
            </Marquee>
          )}

          {/* <div> */}

          <Marquee velocity={xs ? 3 : 14} direction="ltr">
            {clientSayings.map((data) => (
              <ClientCard {...data} />
            ))}
          </Marquee>

          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ClientSay;
