import { Button, Checkbox, Select, Skeleton, Typography } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlanAddOns,
  resetAddOns,
  setSelectedStep,
  setServiceAddonBySelect,
  setServiceAddons,
  toggleContinue,
} from "../../Redux/Slices/PlanSlice";
import goback from "../../Assets/Icons/chevron-left.svg";
import {
  checkDefaultAddon,
  currencyFormatter,
  groupedArray,
} from "../../Utils/helpers";

const AddOns = () => {
  const {
    selectedPlanNew,
    selectedStateNew,
    selectedServiceNew,
    summary,
    planAddOns,
    loading,
    selectedStep,
  } = useSelector((state) => state.planSlice);
  const dispatch = useDispatch();
  const handleService = (e, price, id, stateId, haveMore) =>
    dispatch(setServiceAddons({ e, price, id, stateId, haveMore }));
  const handleSelectService = (t, price, id, stateId) =>
    dispatch(setServiceAddonBySelect({ t, price, id, stateId }));
  const disableChecker = () => {
    let temp = 0;
    summary.addons.map((addon) => {
      if (addon.haveMore === "hasMoreAddons") {
        if (!addon.additionalItemId) {
          temp += 1;
        }
      } else if (addon.haveMore === "hasMoreStates") {
        if (!addon.additionalStateId) {
          temp += 1;
        }
      }
    });
    if (temp === 0) return false;
    else return true;
  };
  useEffect(() => {
    selectedPlanNew?.statePricing
      ? dispatch(
          getPlanAddOns({
            serviceId: selectedServiceNew.id,
            planId: selectedPlanNew.id,
            stateId: selectedStateNew.id,
          })
        )
      : dispatch(
          getPlanAddOns({
            serviceId: selectedServiceNew.id,
            planId: selectedPlanNew.id,
          })
        );
  }, []);

  const PlanRenderer = (props) => {
    const showDropDown = () =>
      summary?.addons?.filter((addOn) => addOn.id === props?.id).length > 0;

    if (props?.additionalItems?.length > 0) {
      const temp = props?.additionalItems?.map(({ name, id }) => {
        return {
          label: name,
          value: id,
          extra: props?.name,
        };
      });

      return (
        <div style={{ width: "100%" }}>
          <div className="add-on-upper-wrapper">
            <div className="add-on-chkbx-container">
              {console.log("props to be sent", props)}
              <Checkbox
                key={props?.id}
                defaultChecked={checkDefaultAddon(props?.id, summary)}
                onChange={(e) =>
                  handleService(
                    e,
                    props?.price,
                    props?.id,
                    props?.addonStateId,
                    "hasMoreAddons"
                  )
                }
                name={props?.name}
              />

              <Typography className="font-14 font-grey-2">
                {" "}
                {props?.name}
              </Typography>
            </div>
            <Typography className="font-14 text-black bold">
              ₹
              {summary.addons.filter((addon) => addon.id === props.id)[0]?.price
                ? currencyFormatter(
                    summary.addons.filter((addon) => addon.id === props.id)[0]
                      ?.price
                  )
                : currencyFormatter(props?.price)}
            </Typography>
          </div>
          {/* {checkContinue()} */}

          {showDropDown() && (
            <>
              <Select
                options={temp}
                placeholder={props?.additionalStepDescription}
                size="small"
                className="add-on-select-field"
                defaultValue={
                  summary?.addons?.filter((data) => data.id === props.id)[0]
                    ?.additionalItemId
                }
                onChange={(e, t) =>
                  handleSelectService(
                    t,
                    props?.price,
                    props?.id,
                    props?.addonStateId
                  )
                }
              />
            </>
          )}
        </div>
      );
    } else if (props?.states?.length > 0) {
      const temp = props?.states?.map(({ state, price, id }) => {
        return {
          label: state?.name,
          value: id,
          data: price,
          extra: props?.name,
        };
      });
      return (
        <div style={{ width: "100%" }}>
          {" "}
          <div className="add-on-upper-wrapper">
            <div className="add-on-chkbx-container">
              <Checkbox
                key={props?.id}
                defaultChecked={checkDefaultAddon(props?.id, summary)}
                onChange={(e) =>
                  handleService(
                    e,
                    props?.price,
                    props?.id,
                    props?.addonStateId,
                    "hasMoreStates"
                  )
                }
                name={props?.name}
              />

              <Typography className="font-14 font-grey-2">
                {" "}
                {props?.name}
              </Typography>
            </div>
            <Typography className="font-14 text-black bold">
              ₹
              {summary.addons.filter((addon) => addon.id === props.id)[0]?.price
                ? currencyFormatter(
                    summary.addons.filter((addon) => addon.id === props.id)[0]
                      ?.price
                  )
                : currencyFormatter(props?.price)}
            </Typography>
          </div>
          {/* {checkContinueStates()} */}
          {showDropDown() && (
            <Select
              options={temp}
              size="small"
              className="add-on-select-field"
              placeholder="Choose State"
              defaultValue={
                summary?.addons?.filter((data) => data.id === props.id)[0]
                  ?.additionalStateId
              }
              onChange={(e, t) =>
                handleSelectService(
                  t,
                  props?.price,
                  props?.id,
                  props?.addonStateId
                )
              }
            />
          )}
        </div>
      );
    } else {
      return (
        <div className="add-on-upper-wrapper">
          <div className="add-on-chkbx-container">
            <Checkbox
              key={props?.id}
              defaultChecked={checkDefaultAddon(props?.id, summary)}
              onChange={(e) =>
                handleService(
                  e,
                  props?.price,
                  props?.id,
                  props?.addonStateId,
                  "hasNothing"
                )
              }
              name={props?.name}
            />

            <Typography className="font-14 font-grey-2">
              {" "}
              {props?.name}
            </Typography>
          </div>
          <Typography className="font-14 text-black bold">
            ₹{props?.price ? currencyFormatter(props?.price) : "N/A"}
          </Typography>
        </div>
      );
    }
  };

  const handleGoBack = () => {
    dispatch(resetAddOns());
    dispatch(setSelectedStep(selectedStep - 1));
  };
  return (
    <div className="add-on-container">
      <Title level={3} className="plan-step-heading d-flex align-center">
        <Button
          icon={<img src={goback} alt="go back" width="28px" />}
          type="text"
          className="go-prev-btn"
          onClick={handleGoBack}
          disabled={disableChecker()}
        />
        Choose your Plan Add On’s
      </Title>
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        groupedArray(planAddOns)?.map(({ data, title }) => (
          <div className="add-on-section">
            {" "}
            <Typography className="add-on-title text-16 bold">
              {" "}
              {title}
            </Typography>
            {data.map((data) => (
              <div className="add-on-container">
                <div className="feature-container">
                  <div className="feature-title-container">
                    <PlanRenderer
                      additionalItems={data?.additionalItems}
                      additionalStepDescription={
                        data?.additionalStepDescription
                      }
                      name={data?.name}
                      states={data?.states}
                      id={data?.id}
                      price={data?.price}
                      addonStateId={data?.addonStateId}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  );
};

export default AddOns;
