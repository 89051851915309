import { Divider } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import React from "react";
import { contactInfos, contactUsNumbers } from "../../Utils/staticsData";

const AddressContainer = ({
  heading,
  line1,
  line2,
  line3,
  lastLine,
  index,
  lastIndex,
}) => (
  <div
    className={
      index === lastIndex
        ? "support-address-container"
        : "last-support-address-container"
    }
  >
    <Paragraph className="support-address-line-1 contact-info-headings">
      {heading}
    </Paragraph>
    <Paragraph className="support-address-line-2 typography-reset-margin contact-info-text">
      {line1}
    </Paragraph>
    <Paragraph className="support-address-line-3 contact-info-text">
      {line2}
    </Paragraph>
    <Paragraph className="support-address-line-4 contact-info-text">
      {line3}
    </Paragraph>
    <Paragraph className="support-address-last-line typography-reset-margin contact-info-text">
      {lastLine}
    </Paragraph>
  </div>
);

const NumberContainer = ({ label, value, index, lastIndex }) => (
  <div className="number-container">
    <Paragraph
      className="
contact-info-headings typography-reset-margin"
    >
      {label}
    </Paragraph>
    <Paragraph
      className="contact-info-text"
      style={index === lastIndex && { marginBottom: 0 }}
    >
      {value}
    </Paragraph>
  </div>
);
const Body2 = () => {
  return (
    <div className="body2-container">
      <div className="body-2-sub-container">
        <Paragraph className="contact-us-sub typography-reset-margin ">
          Contact Us
        </Paragraph>
        <Paragraph className="contact-us-sub-desc">
          Need help? Have a question about your order or want to reach out to
          us? We are always happy to hear from you!
        </Paragraph>
        <Divider />
        <div className="number-wrapper">
          {" "}
          {contactUsNumbers.map((number, index) => (
            <NumberContainer
              {...number}
              index={index}
              lastIndex={contactUsNumbers.length - 1}
            />
          ))}
        </div>

        <Divider />
        <div className="customer-support-container">
          {contactInfos.map((info, index) => (
            <AddressContainer
              {...info}
              index={index}
              lastIndex={contactUsNumbers.length - 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Body2;
