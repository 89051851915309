import Paragraph from "antd/es/typography/Paragraph";
import React from "react";
import "./index.scss";
import { ReactSVG } from "react-svg";
import { Divider, Grid, Typography } from "antd";
import partnerDotLeft from "../../Assets/Images/partner-dot-left.svg";
import partnerDotRight from "../../Assets/Images/partner-dot-right.svg";
import mobileDotRt from "../../Assets/Images/mobile-partner-rt.svg";

const PartnerContainer = ({ title, logos, number, text }) => {
  const startLogo = logos?.slice(0, 3);
  const endLogo = logos?.slice(3);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  return (
    <div className="partner-container">
      <Typography className="partner-main-heading">{title}</Typography>
      <Typography>
        <span className="text-primary partner-number">{number}+ </span>
        <span className="partner-text-span">{text}</span>
      </Typography>
      <div className="logos-wrapper  upper-logo-container">
        {startLogo?.map((logo) => (
          <div className="logo-container">
            <img
              src={logo}
              alt="logo" // style={{ width: "90px" }}
              className="partner-logo-img"
            />
          </div>
        ))}
      </div>
      <div
        className="logos-wrapper lower-logo-container"
        style={{ marginTop: "16px" }}
      >
        {endLogo?.map((logo) => (
          <div className="logo-container ">
            <img
              src={logo}
              alt="logo" // style={{ width: "90px" }}
              className="partner-logo-img"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
const TrustedBy = ({ companyLogos, customerLogos }) => {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  return (
    <div className="partners-container">
      <div className="partner-main-wrapper">
        <div className="partners-left">
          <PartnerContainer
            title="We’ve networked with"
            logos={companyLogos}
            number={15}
            text="commerce partners"
          />
        </div>
      </div>
      <div className="partner-divider-container">
        <Divider
          type={xs ? "horizontal" : "vertical"}
          className="partner-divider"
        />
      </div>

      <div className="partner-main-wrapper">
        <div className="partners-right">
          <PartnerContainer
            title="Trusted and used by"
            logos={customerLogos}
            number={20000}
            text="customers"
          />
        </div>
      </div>
      {!xs && (
        <img
          src={partnerDotLeft}
          width="166px"
          alt="left-dot"
          className="partner-dot-left"
        />
      )}

      <img
        src={xs ? mobileDotRt : partnerDotRight}
        width={xs ? "87px" : "166px"}
        alt="right-dot"
        className="partner-dot-right"
      />
    </div>
  );
};

export default TrustedBy;
