import { Button, Modal, Typography } from "antd";
import React from "react";
import success from "../../../Assets/Images/sucess-image.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { closeLeadGeneratedDialog } from "../../../Redux/Slices/PlanSlice";

const LeadGenerated = () => {
  const { leadGenerated } = useSelector((state) => state.planSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOk = () => {
    dispatch(closeLeadGeneratedDialog());
    navigate(0);
  };
  return (
    <Modal
      open={leadGenerated}
      footer={null}
      className="payment-modal"
      // onCancel={handleCancel}
      closable={false}
      centered
    >
      <img src={success} alt="success" />
      <Typography className="payment-modal-title">
        Lead Generated! 🎉
      </Typography>
      <Typography className="payment-modal-description font-grey-2 ">
        Thank you for your request. Our team will reach out to you within the
        next 24 hours to assist you with the next steps. If you have any
        questions in the meantime, feel free to contact us.
      </Typography>
      <Button
        type="primary"
        htmlType="submit"
        // style={{ width: "100%" }}
        className="contained-primary  payment-success-dialog-cta"
        onClick={handleOk}
      >
        Continue
      </Button>
    </Modal>
  );
};

export default LeadGenerated;
