import React, { useEffect } from 'react';
import './index.scss';
import Title from 'antd/es/typography/Title';
import { Col, Row } from 'antd';
import { pricingData } from '../../../../Utils/staticsData';
import PricingCard from './Card';
import CustomisedQuote from './CustomisedQuote';
import { fancyUlTextRenderer } from '../../../../Utils/renderer';
import fancyLine from '../../../../Assets/Icons/styled-white.svg';

const Pricing = () => {
  return (
    <div className="pricing-container">
      <Title level={2} className="font-400 pricing font-grey-2 ">
        {' '}
        Decide your{' '}
        {fancyUlTextRenderer(fancyLine, 'bold', 'office pricing', '#FFFFFF')}
      </Title>
      <div className="pricing-card-container">
        <Row gutter={[22, 22]}>
          {pricingData.map((data, index) => (
            <Col xs={24} md={8} className="card-col">
              {' '}
              <PricingCard {...data} index={index} />
            </Col>
          ))}
        </Row>
      </div>
      <CustomisedQuote />
    </div>
  );
};

export default Pricing;
