import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiStatics } from "../../Utils/apiStatics";
import axios from "axios";

const initialState = { loading: false, blogs: [], total: 0, blogDetails: {} };

export const getAllBlogs = createAsyncThunk(
  `blog/getAllBlogs`,
  async ({ page, limit }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/${apiStatics.blogs.blogs}?page=${page}&limit=${limit}`
      );
      return {
        data: response.data.data.data,
        total: response.data.total,
      };
    } catch (error) {
      console.log("error", error);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
export const getBlogById = createAsyncThunk(
  `blog/getBlogById`,
  async ({ id }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/${apiStatics.blogs.blogs}/${id}`
      );
      return {
        data: response.data.data.data,
      };
    } catch (error) {
      console.log("error", error);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    // Define your reducer functions here
    setSelectedStep: (state, action) => {
      state.selectedStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBlogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBlogs.fulfilled, (state, action) => {
        state.loading = false;
        state.blogs = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(getAllBlogs.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getBlogById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBlogById.fulfilled, (state, action) => {
        state.loading = false;
        state.blogDetails = action.payload.data;
      })
      .addCase(getBlogById.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  /* Add your action creators here */
} = blogSlice.actions;
export default blogSlice.reducer;
