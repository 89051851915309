import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import { State, City } from "country-state-city";
import React, { useEffect, useState } from "react";
import SuccessDialog from "../../Components/Dialog/SuccessfulPayment";
import PaymentDeclined from "../../Components/Dialog/PaymentDeclined";
import goback from "../../Assets/Icons/chevron-left.svg";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedStep } from "../../Redux/Slices/PlanSlice";

const PaymentDetail = ({
  form,
  handleFormFinish,
  setIsECommerce,
  isECommerce,
}) => {
  const { selectedStep } = useSelector((state) => state.planSlice);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const dispatch = useDispatch();
  const [stateCode, setStateCode] = useState(null);
  const handleState = (stateCode, e) => {
    form.setFieldsValue({ state: e.label });
    form.setFieldsValue({ city: null });
    setStateCode(stateCode);
  };

  useEffect(() => {
    const temp = [];
    State.getStatesOfCountry("IN").map(({ name, isoCode }) =>
      temp.push({ label: name, value: isoCode })
    );
    setStates(temp);
  }, []);
  const handleCheck = () => setIsECommerce(!isECommerce);
  useEffect(() => {
    const temp = [];
    City.getCitiesOfState("IN", stateCode).map(({ name, isoCode }) =>
      temp.push({ label: name, value: name })
    );
    setCities(temp);
  }, [stateCode]);
  const handleGoBack = () => dispatch(setSelectedStep(selectedStep - 1));
  return (
    <div className="payment-detail-container">
      <div className="plan-container">
        <Title level={3} className="plan-step-heading d-flex align-center">
          <Button
            icon={<img src={goback} alt="go back" width="28px" />}
            type="text"
            className="go-prev-btn"
            onClick={handleGoBack}
          />
          Add your Payment Details
        </Title>

        <Form
          form={form}
          style={{ marginTop: "40px" }}
          layout="vertical"
          onFinish={handleFormFinish}
          initialValues={{
            companyName: "",
            gst: "",
            address: "",
            phone: "",
            email: "",
            name: "",
            state: "",
            city: "",
          }}
        >
          <Row gutter={[16, 20]}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Company Name"
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "Please enter company name",
                  },
                ]}
              >
                <Input placeholder="Company name" size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="GSTIN"
                name="gst"
                rules={[
                  {
                    required: true,
                    message: "Enter GSTIN",
                  },
                ]}
              >
                <Input placeholder="Enter GSTIN" size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Address line 1"
                name="line1"
                rules={[
                  {
                    required: true,
                    message: "Enter address",
                  },
                ]}
              >
                <Input placeholder="Enter address" size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Address line 2"
                name="line2"
                rules={[
                  {
                    required: true,
                    message: "Enter address",
                  },
                ]}
              >
                <Input placeholder="Enter address" size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="State"
                name="state"
                placeholder="Select state"
                rules={[
                  {
                    required: true,
                    message: "Select state",
                  },
                ]}
              >
                <Select
                  size="large"
                  options={states}
                  placeholder="Select state"
                  onChange={(e, t) => handleState(e, t)}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="City"
                name="city"
                placeholder="Select city"
                rules={[
                  {
                    required: true,
                    message: "Select city",
                  },
                ]}
              >
                <Select
                  placeholder="Select city"
                  size="large"
                  options={cities}
                  disabled={cities.length === 0}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Pincode"
                name="pin"
                rules={[
                  {
                    required: true,
                    message: "Enter pincode",
                  },
                ]}
              >
                <Input placeholder="Enter pincode" size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Phone number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Enter phone number",
                  },
                ]}
              >
                <Input placeholder="Phone number" size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Enter Email",
                  },
                ]}
              >
                <Input placeholder="Enter your email" size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Enter Name",
                  },
                ]}
              >
                <Input placeholder="Enter your name" size="large" />
              </Form.Item>
            </Col>
            <div className="form-ecom-chkbox-wrapper-2">
              {" "}
              <Checkbox
                size="large"
                checked={isECommerce}
                onChange={handleCheck}
              />
              <Typography className="form-ecom-text">
                Are you an E-Commerce seller?
              </Typography>
            </div>
          </Row>
        </Form>
      </div>
      <SuccessDialog />
      <PaymentDeclined handleFormFinish={handleFormFinish} />
    </div>
  );
};

export default PaymentDetail;
