import { Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const ServiceCard = ({ label, icon, faq_Config, description, name }) => {
  return (
    <div className="service-card-container">
      <Link
        // className="service-card-link"
        to="/contact-us"
        state={{
          label,
          faq: JSON.stringify(faq_Config),
          description,
          name,
        }}
      >
        <div className="vpob-service-card">
          {" "}
          <Typography className="vpob-service-link-label" variant="body1">
            {label}
          </Typography>
          <img src={icon} alt="mini-map" height="40px" />
        </div>
      </Link>
    </div>
  );
};

export default ServiceCard;
