import { Typography } from "antd";
import React from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedServiceNew } from "../../Redux/Slices/PlanSlice";

const ServiceCard = ({ name, imageUrl, id, plans, statePricing }) => {
  const { selectedServiceNew } = useSelector((state) => state.planSlice);
  const dispatch = useDispatch();
  const handleSelectedCard = () =>
    dispatch(
      setSelectedServiceNew({ name, imageUrl, id, plans, statePricing })
    );
  const plansRender = (plans) => {
    const temp = [...plans];
    const reducedPlans = temp.slice(0, 3);

    if (plans.length > 3) {
      return (
        <>
          {reducedPlans?.map(({ name }, index) => (
            <Typography className="font-grey-2 text-12">
              {name}
              {index !== plans.length - 1 && (
                <span style={{ marginRight: "4px" }}>, </span>
              )}
            </Typography>
          ))}
          <span className="text-primary ">+ {plans.length - 3} more</span>
        </>
      );
    } else
      return plans?.map(({ name }, index) => (
        <Typography className="font-grey-2 text-12">
          {name}
          {index !== plans.length - 1 && (
            <span style={{ marginRight: "4px" }}>, </span>
          )}
        </Typography>
      ));
  };
  return (
    <div
      className={`service-card cursor ${
        selectedServiceNew.id === id ? "selected-card" : "unselected-card"
      }`}
      onClick={handleSelectedCard}
    >
      <div>
        <Typography className="text-16 bold service-card-heading">
          {name}
        </Typography>
        <div className="extra-services">{plansRender(plans)}</div>
      </div>
      {selectedServiceNew.id === id && (
        <img
          src={imageUrl}
          alt="ofc-img"
          width="66px"
          className="office-plan-img"
        />
      )}
    </div>
  );
};

export default ServiceCard;
