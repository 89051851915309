import { Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import arrowRight from "../../../Assets/Icons/chevron-right-grey.svg";

const ServiceCard = ({ label, faq_Config, description, name }) => {
  return (
    <div className="service-card-container">
      <Link
        // className="service-card-link"
        to="/contact-us"
        state={{
          label,
          faq: JSON.stringify(faq_Config),
          description,
          name,
        }}
      >
        <div className="vpob-service-card">
          {" "}
          <Typography className="vpob-service-link-label" variant="body1">
            {label}
          </Typography>
          <img
            src={arrowRight}
            alt="rt-arrow"
            width="24px"
            // className="service-link-arrow"
          />
        </div>
      </Link>
    </div>
  );
};

export default ServiceCard;
