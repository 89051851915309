import { configureStore } from "@reduxjs/toolkit";
import PlanSlice from "./Slices/PlanSlice";
import BlogSlice from "./Slices/BlogSlice";

const Store = configureStore({
  reducer: {
    planSlice: PlanSlice,
    blogSlice: BlogSlice,
  },
});

export default Store;
