import React from 'react';
import './index.scss';
import Column1 from './Column1';
import Column2 from './Column2';
import Paragraph from 'antd/es/typography/Paragraph';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <Column1 />
        <Column2 />
      </div>
      <div className="copyright-container">
        <Link to="/terms-of-service">
          {' '}
          <Paragraph className="footer-copyright typography-reset-margin">
            Terms of Service
          </Paragraph>
        </Link>
        <Link to="/refund-policy">
          {' '}
          <Paragraph className="footer-copyright typography-reset-margin">
            Refund Policy
          </Paragraph>
        </Link>
        <Link to="/privacy-policy">
          {' '}
          <Paragraph className="footer-copyright typography-reset-margin">
            Privacy policy
          </Paragraph>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
