import { Typography } from "antd";
import React from "react";

const ServiceCard = ({ label, description, icon }) => {
  return (
    <div className="service-card-container">
      <div className="fixed-desk-advantage-card">
        {" "}
        <div>
          <Typography className="vpob-service-link-label" variant="body1">
            {label}
          </Typography>
          <Typography
            className="fixed-desk-advantage-description"
            variant="body1"
          >
            {description}
          </Typography>
        </div>
        <img
          src={icon}
          alt="rt-arrow"
          width="24px"
          className="fixed-desk-advant-image"
        />
      </div>
    </div>
  );
};

export default ServiceCard;
