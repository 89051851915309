import { Skeleton, Typography } from "antd";
import Title from "antd/es/typography/Title";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import { getBlogById } from "../../Redux/Slices/BlogSlice";

const ViewBlog = () => {
  const { blogDetails, loading } = useSelector((state) => state.blogSlice);
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBlogById({ id }));
  }, []);

  return (
    <div className="view-blog-wrapper">
      {loading ? (
        <Skeleton />
      ) : (
        <div className="preview-blog-container">
          <Typography className="font-grey-2 blog-card-date">
            {moment(blogDetails?.createdAt).format("ll")}
          </Typography>
          <Title level={3} className="preview-blog-heading">
            {blogDetails?.title}
          </Title>
          <div
            className="view-blog-img"
            style={{ backgroundImage: `url(${blogDetails?.thumbnailUrl})` }}
          />
          {/* <img src={blogDetails?.thumbnailUrl} width="671px" alt="blog-img" /> */}
          <Typography className="preview-blog-content">
            {blogDetails?.content && parse(blogDetails?.content)}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ViewBlog;
