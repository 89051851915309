import { Modal } from "antd";
import React from "react";
import { bookingErrRenderer } from "../../../Utils/renderer";

const BookingErrorHandler = ({
  open,
  closeDialog,
  type,
  userName,
  businessId,
  formData,
  closeDialogOnly,
}) => {
  return (
    <Modal
      open={open}
      footer={null}
      className="payment-modal"
      onCancel={closeDialogOnly}
      closable={true}
      centered
    >
      {bookingErrRenderer(
        type,
        closeDialog,
        userName,
        businessId,
        formData,
        closeDialogOnly
      )}
    </Modal>
  );
};

export default BookingErrorHandler;
