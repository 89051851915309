import React from "react";
import "./index.scss";
import Title from "antd/es/typography/Title";
import { useLottie } from "lottie-react";
import gstLottie from "../../../../Assets/Lotties/stats_lottie.json";
import { gstServices } from "../../../../Utils/staticsData";
import GSTCard from "./GSTCard";
import { Col, Row } from "antd";

const GSTServices = () => {
  const options = {
    animationData: gstLottie,
    loop: true,
  };

  const { View } = useLottie(options);
  return (
    <div className="internal-gst-container">
      <Title level={2} className="gst-services-heading ">
        GST Services
      </Title>
      <Row gutter={[24, 70]} className="gst-service-card-container">
        {gstServices.map((service) => (
          <Col xs={24} md={12}>
            {" "}
            <GSTCard {...service} />
          </Col>
        ))}
      </Row>

      {/* <div className="gst-lottie">{View}</div> */}
    </div>
  );
};

export default GSTServices;
