const ThemeConfig = {
  token: {
    // Seed Token
    colorPrimary: "#D77D13",
  },
  components: {
    Typography: {
      fontSizeHeading1: 56,
      fontSizeHeading2: 48,
      fontSizeHeading3: 24,
      fontSizeHeading4: 20,
      fontSizeHeading5: 16,
      fontSizeHeading6: 14,
      colorTextHeading: "#2F2F30",
      colorText: "#2F2F30",
    },
    Input: {
      activeBg: "#FFFFFF",
      colorBgContainer: "#FAFAFA",
      // colorBorder: "#F7EFE5",

      // activeShadow: "none",
      // errorActiveShadow: "none",
      // warningActiveShadow: "none",
      // borderRadius: 10,
      // inputFontSizeLG: 18,
      hoverBorderColor: "#D77D13",
      // colorError: "rgb(246, 76, 76)",
      // colorBgContainerDisabled: "rgb(225, 225, 225)",
      // colorTextDisabled: "rgb(142, 142, 142)",
      // colorTextPlaceholder: "#8E8E8E",
      // activeBorderColor: "#5D5BF6",
      // paddingInline: 16,
      // paddingInlineLG: 16,
    },
    Select: {
      // fontSize: 18,
      // fontSizeLG: 18,
      // colorBgContainer: "#fff",
      colorText: "#2F2F30",
      // activeBorderColor: "#5D5BF6",
      // optionPadding: "5px 16px",
      // paddingSM: 16,
    },
  },
};

export default ThemeConfig;
