import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import Router from "./Config/routes";
import Store from "./Redux/store";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/all";
import ThemeConfig from "./Config/theme";
import { useEffect } from "react";
import { isStickyNav } from "./Utils/helpers";

function App() {
  useEffect(() => {
    window.addEventListener("scroll", isStickyNav);

    return () => {
      window.removeEventListener("scroll", isStickyNav);
    };
  }, []); // Empty dependency array ensures that the effect runs once (like componentDidMount)

  gsap.registerPlugin(MotionPathPlugin);
  return (
    <Provider store={Store}>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            ...ThemeConfig,
          }}
        >
          <div>
            <Router />
          </div>
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
