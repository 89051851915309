import Paragraph from "antd/es/typography/Paragraph";
import React from "react";
import phone from "../../../Assets/Icons/phone.svg";
import pin from "../../../Assets/Icons/pin_white.svg";
import logo from "../../../Assets/Images/logo.svg";
import { ReactSVG } from "react-svg";

const FooterContact = ({ image, title, data }) => (
  <div className="footer-contact">
    <img src={image} alt="footer-contact-img" className="footer-contact-img" />
    <div>
      <Paragraph className="text-16">{title}:</Paragraph>
      <Paragraph>{data}</Paragraph>
    </div>
  </div>
);

const Column1 = () => {
  return (
    <div className="footer-column-1">
      {/* <Title level={2} className="lexend footer-logo typography-reset-margin">
        EW.
      </Title> */}
      <ReactSVG src={logo} className="white-logo" />
      <FooterContact image={phone} title="Phone" data="+91 9717174320" />
      <FooterContact
        image={pin}
        title="Address"
        data="3198, Basement, Palam Vihar, Sector - 23, Gurguram, Haryana - 122017"
      />
    </div>
  );
};

export default Column1;
