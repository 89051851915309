import { Button } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import React from "react";
import arrowRight from "../../../../Assets/Images/arrow-narrow-right-blk.svg";
import personalizedImage from "../../../../Assets/Images/peeps.png";
import { Link } from "react-router-dom";

const CustomisedQuote = () => {
  return (
    <div className="customised-quote-container ">
      {/* <img src={dotImage} className="customised-quote-image-1" alt="dots" /> */}
      <div className="personalized-left">
        <Title
          level={2}
          className=" typography-reset-margin customized-quote-heading text-white"
        >
          Personalised service for large enterprise
        </Title>
        <Link to="/contact-us-basic">
          <Button
            className="personalized-cta"
            icon={<img src={arrowRight} width="100%" alt="banner-img" />}
          >
            Get in touch
          </Button>
        </Link>
      </div>
      <div className="personalized-right">
        <img
          src={personalizedImage}
          alt="personalized"
          className="personalized-image"
        />
      </div>
    </div>
  );
};

export default CustomisedQuote;
