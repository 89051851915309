import React from "react";
import "./index.scss";
import Title from "antd/es/typography/Title";
import { Button, Col, Row, Typography } from "antd";
import arrowRight from "../../../../Assets/Images/thin-arr-rt.svg";
import fancyLine from "../../../../Assets/Icons/styled-orange.svg";
import { easyProcessData } from "../../../../Utils/staticsData";
import ProcessCard from "./Card";
import { fancyUlTextRenderer } from "../../../../Utils/renderer";
import { Link } from "react-router-dom";

const EasyProcess = () => {
  return (
    <div className="easy-process-container">
      <div className="easy-process-left">
        <Title className="easy-process-heading">
          Our easy process{" "}
          {fancyUlTextRenderer(
            fancyLine,
            "bold",
            " to help you start",
            "#D77D13"
          )}
        </Title>
        <Typography className="easy-process-description font-grey-2">
          Rest easy, we’ve got your financial and legal compliances covered.
          Leave the complexities to us.
        </Typography>
        <Link to="/contact-us-basic">
          <Button
            className="contained-primary fingertip-cta"
            icon={<img src={arrowRight} width="100%" alt="banner-img" />}
          >
            Get Started
          </Button>
        </Link>
      </div>
      <Row className="easy-process-right" gutter={[20, 20]}>
        {easyProcessData.map((item, index) => (
          <Col xs={24} md={12}>
            <ProcessCard {...item} index={index} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default EasyProcess;
