import React, { useRef, useState } from "react";
import "./index.scss";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { Button, Card, Checkbox, Form, Input, Select, Typography } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-web";
import phone from "../../Assets/Lotties/call.json";
import fancyLine from "../../Assets/Icons/styled-white.svg";
import dropdown from "../../Assets/Icons/select-drpdwn.svg";
import { fancyUlTextRenderer } from "../../Utils/renderer";
import { createLead } from "../../Redux/Slices/PlanSlice";
import LeadGenerated from "../Dialog/LeadCreated";

const GetStarted = () => {
  const { updateLoading } = useSelector((state) => state.planSlice);
  const container = useRef();
  const dispatch = useDispatch();
  const [isECommerce, setIsECommerce] = useState(false);
  useEffect(() => {
    Lottie.loadAnimation({
      name: `animation`,
      container: container.current,
      renderer: "svg",
      loop: true,
      // autoplay: false,
      animationData: phone,
    });

    return () => {
      Lottie.destroy();
    };
  }, []);
  const handleCheck = () => setIsECommerce(!isECommerce);
  const handleFormFinish = (values) =>
    dispatch(
      createLead({
        values: {
          companyName: values.companyName,
          clientName: values.clientName,
          service: values.service,
          contactDetails: values.email + "," + values.number,
          message: "Free Consultation",
          isECommerce,
        },
      })
    );
  return (
    <div className="get-started-container">
      <div className="get-started-left">
        <Title level={1} className="touch-text  bold text-white">
          {" "}
          Get in touch with us for a{" "}
          {fancyUlTextRenderer(
            fancyLine,
            "bold",
            "Free Consultation call!",
            "#FFFFFF"
          )}
        </Title>
        <Paragraph className="text-16 text-grey-4  get-started-description">
          Get a personalised quote for all your compliance needs. From Startups
          compliance to Fortune 500 legals we cover it all.
        </Paragraph>
      </div>

      <div className=" banner-form-wrapper get-started-form-wrapper">
        <div className="banner-form bottom-form">
          <Title className="consultation-form-header">
            Get your free consultation by expert
          </Title>
          <Form
            className="banner-form"
            onFinish={handleFormFinish}
            initialValues={{
              companyName: "",
              clientName: "",
              email: "",
              number: "",
              service: "VPOB & APOB",
              message: "Free Consultation",
            }}
          >
            <Form.Item
              // label=""
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Please enter company name",
                },
              ]}
              className="banner-field-form-item"
            >
              <Input placeholder="Company name" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="clientName"
              rules={[
                {
                  required: true,
                  message: "Please enter your name",
                },
              ]}
              className="banner-field-form-item"
            >
              <Input placeholder="Your name" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="number"
              className="banner-field-form-item"
              rules={[
                {
                  pattern:
                    /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g,
                  message: "Please enter valid number",
                },
                {
                  required: true,
                  message: "Please enter your contact number",
                },
              ]}
            >
              <Input placeholder="Phone Number" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="email"
              rules={[
                {
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Enter valid email only",
                },
                {
                  required: true,
                  message: "Please enter your email id",
                },
              ]}
              className="banner-field-form-item"
            >
              <Input placeholder="Email ID" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="service"
              rules={[]}
              // style={{ flex: 1, marginRight: "12px", width: "100%" }}
              className="banner-field-form-item"
            >
              <Select
                defaultValue="vpobAndApob"
                size="large"
                suffixIcon={<img src={dropdown} width="24px" alt="ddwn" />}
                options={[
                  {
                    value: "VPOB & APOB",
                    label: "VPOB & APOB",
                  },
                  {
                    value: "Startup and Compliance",
                    label: "Startup and Compliance",
                  },
                  {
                    value: "GST Advisory",
                    label: "GST Advisory",
                  },
                  {
                    value: "Fixed Desk",
                    label: "Fixed Desk",
                  },
                ]}
              />
            </Form.Item>
            <div className="form-ecom-chkbox-wrapper-2">
              {" "}
              <Checkbox
                size="large"
                checked={isECommerce}
                onChange={handleCheck}
              />
              <Typography className="form-ecom-text">
                Are you an E-Commerce seller?
              </Typography>
            </div>
            <Form.Item
              wrapperCol={{
                // offset: 8,
                span: 32,
              }}
              // style={{ width: "100%" }}
              className="banner-cta-form-item"
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", padding: "12px, 24px, 12px, 24p" }}
                className="contained-primary home-bottom-form-cta"
                size="large"
                disabled={updateLoading}
              >
                Contact Us Now
              </Button>
            </Form.Item>
            <br />
          </Form>
        </div>
        {/* <img src={human} alt="human" className="human-image" /> */}
      </div>
      <LeadGenerated />
    </div>
  );
};

export default GetStarted;
