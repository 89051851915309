import { Button, Collapse, Divider, Drawer, Typography } from "antd";
import React, { useState } from "react";
import logo from "../../../Assets/Images/logo.svg";
import menu from "../../../Assets/Images/mobile-menu.svg";
import { faq_Config, mobileLinks } from "../../../Utils/staticsData";
import user from "../../../Assets/Icons/user-alt-1.svg";
import house from "../../../Assets/Icons/house-window.svg";
import { Link, useNavigate } from "react-router-dom";
import arrowDwn from "../../../Assets/Icons/chevron-down.svg";
import { useDispatch } from "react-redux";
import { resetSteps } from "../../../Redux/Slices/PlanSlice";

const MobileBar = () => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Text } = Typography;
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const handleBook = () => {
    dispatch(resetSteps());
    navigate("/booking");
  };
  return (
    <div className="mobile-bar-container">
      <Link to="/">
        {" "}
        <img src={logo} width="118px" alt="logo" />
      </Link>

      <img src={menu} alt="lmenuogo" onClick={showDrawer} />

      <Drawer onClose={onClose} open={open} width="276px" closable={false}>
        <Collapse
          items={mobileLinks && mobileLinks}
          ghost
          expandIcon={<img src={arrowDwn} width="20px" alt="chevron" />}
          expandIconPosition="end"
          showArrow={false}
        />
        <Link to="/fixed-desk" state={{ faq: JSON.stringify(faq_Config) }}>
          <Typography className="fixed-desk-link">Fixed Desk</Typography>
        </Link>
        <Divider style={{ marginTop: 0, marginBottom: 0 }} />
        <Link
          to="/contact-us-basic"
          state={{ faq: JSON.stringify(faq_Config) }}
        >
          <Typography className="fixed-desk-link">Contact Us</Typography>
        </Link>

        <div className="nav-action-container">
          {/* <Button
            className="contained-white nav-btns login-nav-btn"
            icon={<img src={user} alt="user" width="20px" />}
          >
            Login
          </Button> */}
          <Button
            className="contained-primary nav-btns book-service-btn"
            icon={<img src={house} alt="wa" width="20px" />}
            onClick={handleBook}
          >
            Book Service
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileBar;
