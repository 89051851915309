import React, { useState } from 'react';
import './index.scss';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import { Link, useLocation } from 'react-router-dom';
import TrustedBy from '../../Components/TrustedBy';
import { useEffect } from 'react';
import Body2 from './Body2';
import { customerLogos, sellerLogos } from '../../Utils/staticsData';
import FAQ from '../../Components/FAQ';
import {
  contactUsExtraRenderer,
  renderInternalTable,
  renderInternalTable2,
} from '../../Utils/renderer';
import { Button, Checkbox, Form, Grid, Input, Select, Typography } from 'antd';
import arrowRight from '../../Assets/Images/btn-rt-arrow.svg';
import dropdown from '../../Assets/Icons/select-drpdwn.svg';
import internalTable from '../../Assets/Images/internalTable.svg';
import { useDispatch, useSelector } from 'react-redux';
import { createLead } from '../../Redux/Slices/PlanSlice';
import LeadGenerated from '../../Components/Dialog/LeadCreated';

const ContactUs = () => {
  const { state } = useLocation();
  const { updateLoading } = useSelector((state) => state.planSlice);
  const [initialValues, setInitialValues] = useState({
    companyName: '',
    clientName: '',
    email: '',
    number: '',
    service: 'VPOB & APOB',
    message: 'Free Consultation',
  });
  const dispatch = useDispatch();
  const faq_Config = JSON.parse(state?.faq);
  const pageVariant = state?.name;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setInitialValues({
      companyName: '',
      clientName: '',
      email: '',
      number: '',
      service: 'VPOB & APOB',
      message: 'Free Consultation',
    });
  }, [state?.label]);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();

  const [isECommerce, setIsECommerce] = useState(false);
  const handleCheck = () => setIsECommerce(!isECommerce);
  const handleFormFinish = (values) =>
    dispatch(
      createLead({
        values: {
          companyName: values.companyName,
          clientName: values.clientName,
          service: values.service,
          contactDetails: values.email + ',' + values.number,
          message: 'Free Consultation',
          isECommerce,
        },
      })
    );
  return (
    <div className="contact-us-container">
      <div className="contact-banner-container">
        <div className="banner-top">
          <div className="banner-content-left">
            <div className="contact-us-banner">
              {state.label ? (
                <Title
                  level={1}
                  className="compliance-banner-middle-text bold typography-reset-margin"
                >
                  {state.label}
                </Title>
              ) : (
                <Title
                  level={1}
                  className="compliance-banner-middle-text bold typography-reset-margin"
                >
                  Contact us
                </Title>
              )}

              <Paragraph className=" banner-description font-300 text-16 text-black  typography-reset-margin">
                {state.description
                  ? state.description
                  : 'Our accounting services cover a wide range of tasks, including bookkeeping, financial statement preparation, budgeting, payroll processing, and tax planning. We ensure that your financial records are accurate, up-to-date, and compliant with relevant accounting standards and regulations. By outsourcing your accounting needs to us, you can focus on core business activities while having peace of mind knowing that your finances are in good hands.'}
              </Paragraph>
              {(state.planId || state.stateId) && (
                <Link
                  to="/booking"
                  state={{
                    serviceId: state.serviceId,
                    planId: state.planId,
                    stateId: state.stateId,
                    dontCall: true,
                  }}
                  style={{ width: 'fit-content' }}
                >
                  <Button
                    className="contained-primary   finest-ofc-cta"
                    icon={
                      <img src={arrowRight} width="100%" alt="banner-img" />
                    }
                    size="sm"
                  >
                    Book Now
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <div className="banner-content-right-home" />
        </div>

        {/* <div className="banner-form"> */}
        <div className="desktop-form-wrapper">
          <Title level={4} className="banner-consultation-lower-heading text ">
            Get your <span className="text-primary">free consultation</span> by
            expert
          </Title>
          <Form
            className="banner-form"
            onFinish={handleFormFinish}
            initialValues={initialValues}
            preserve={false}
          >
            <Form.Item
              // label=""
              name="companyName"
              rules={[
                {
                  required: true,
                  message: 'Please enter company name',
                },
              ]}
              className="banner-field-form-item"
            >
              <Input placeholder="Company name" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="clientName"
              rules={[
                {
                  required: true,
                  message: 'Please enter your name',
                },
              ]}
              className="banner-field-form-item"
            >
              <Input placeholder="Your name" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="number"
              className="banner-field-form-item"
              rules={[
                {
                  pattern:
                    /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g,
                  message: 'Please enter valid number',
                },
                {
                  required: true,
                  message: 'Please enter your contact number',
                },
              ]}
            >
              <Input placeholder="Phone Number" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="email"
              rules={[
                {
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Enter valid email only',
                },
                {
                  required: true,
                  message: 'Please enter your email id',
                },
              ]}
              className="banner-field-form-item"
            >
              <Input placeholder="Email ID" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="service"
              rules={[]}
              // style={{ flex: 1, marginRight: "12px", width: "100%" }}
              className="banner-field-form-item"
            >
              <Select
                defaultValue="vpobAndApob"
                size="large"
                suffixIcon={<img src={dropdown} width="24px" alt="ddwn" />}
                options={[
                  {
                    value: 'VPOB & APOB',
                    label: 'VPOB & APOB',
                  },
                  {
                    value: 'Startup and Compliance',
                    label: 'Startup and Compliance',
                  },
                  {
                    value: 'GST Advisory',
                    label: 'GST Advisory',
                  },
                  {
                    value: 'Fixed Desk',
                    label: 'Fixed Desk',
                  },
                ]}
              />
            </Form.Item>
            <div className="form-ecom-chkbox-wrapper-mobile">
              {' '}
              <Checkbox
                size="large"
                checked={isECommerce}
                onChange={handleCheck}
              />
              <Typography className="form-ecom-text">
                Are you an E-Commerce seller?
              </Typography>
            </div>
            <Form.Item
              wrapperCol={{
                // offset: 8,
                span: 32,
              }}
              // style={{ width: "100%" }}
              className="banner-cta-form-item"
            >
              <Button
                type="primary"
                htmlType="submit"
                disabled={updateLoading}
                className="contained-primary  banner-form-cta"
                size="small"
                icon={<img src={arrowRight} width="100%" alt="banner-img" />}
              >
                {xs && 'Continue'}
              </Button>
            </Form.Item>
            <br />
          </Form>
          <div className="form-ecom-chkbox-wrapper">
            {' '}
            <Checkbox
              size="large"
              checked={isECommerce}
              onChange={handleCheck}
            />
            <Typography className="form-ecom-text">
              Are you an E-Commerce seller?
            </Typography>
          </div>
        </div>
        {/* </div> */}
      </div>

      <div className="rest-trusted">
        {' '}
        <TrustedBy
          companyLogos={sellerLogos}
          width={xs ? ':100%' : '1100px'}
          customerLogos={customerLogos}
        />
      </div>
      <div className="contact-us-extra">
        {contactUsExtraRenderer(pageVariant)}
      </div>
      {renderInternalTable2(state?.name)}

      {renderInternalTable(state?.name) && (
        <div className="internal-page-table-container">
          <Title level={2} className="gst-services-heading ">
            Features
          </Title>
          <div className="internal-table-wrapper">
            <img
              src={internalTable}
              className="internal-table"
              alt="internal-table"
            />
          </div>
        </div>
      )}

      <div className="fullwidth">
        <FAQ faq_Config={faq_Config} />
      </div>
      <Body2 />
      <LeadGenerated />
    </div>
  );
};

export default ContactUs;
