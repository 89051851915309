import Paragraph from "antd/es/typography/Paragraph";
import tick from "../Assets/Icons/tick.svg";
import headphones from "../Assets/Icons/headphones.svg";
import declined from "../Assets/Images/declined-image.svg";
import parse from "html-react-parser";
import {
  accountingLines,
  addRemoveDirectorLines,
  additionalRegProTaxLines,
  additionalTradeLines,
  additionalfssaiLines,
  amazonEcommerceLines,
  bookkeepingLines,
  businessRegistrationLines,
  businessTaxFilingLines,
  changeInShareCapitalLines,
  digitalSignatureLines,
  flipkartEcommerceLines,
  form16Lines,
  fssaiRegLines,
  gstAnnualReturnsLines,
  gstApobLines,
  gstAppealLines,
  gstCancellationLines,
  gstFilingLines,
  gstRefundLines,
  gstRegistrationLines,
  gstReturnsLines,
  gstRevocationLines,
  importExportCodeLines,
  llcComplianceLines,
  llpClosureLines,
  ltdLiabilityLines,
  nidhiLines,
  onePersonLines,
  partnershipLines,
  payrollLines,
  personalTaxFilingLines,
  proTaxLines,
  proprietorshipLines,
  pvtLtdLines,
  regOfficeAddressChangeLines,
  rocCompliancePVTLTDLines,
  rocDIRLines,
  rocLines,
  section8Lines,
  showCauseLines,
  startupIndiaLines,
  tdsFilingLines,
  tradeLicenseLines,
  udyamLines,
} from "./staticsData";
import StartupService from "../Components/Services/Startup/Startup";
import VPOBService from "../Components/Services/VPOB";
import GSTService from "../Components/Services/GST";
import FixedDeskServices from "../Components/Services/FixedDesk";
import { ReactSVG } from "react-svg";
import Plans from "../Containers/Booking/Plans";
import Tenure from "../Containers/Booking/Tenure";
import AddOns from "../Containers/Booking/AddOns";
import PaymentDetail from "../Containers/Booking/PaymentDetail";
import Title from "antd/es/typography/Title";
import complianceTable from "../Assets/Images/prop_compliance_table.svg";
import licenseTable from "../Assets/Images/prop_license_table.svg";
import partnerCompliance from "../Assets/Images/partnership_compliance_table.svg";
import partnerLicense from "../Assets/Images/partnership_license_Registrations_.svg";
import oneCompliance from "../Assets/Images/one_Compliances_.svg";
import oneComparo from "../Assets/Images/one_comparo.svg";
import pvtCompliance from "../Assets/Images/pvt_compliance.svg";
import section8Compliance from "../Assets/Images/section8_compliance.svg";
import nidhiCompliance from "../Assets/Images/nidhi_compliance.svg";
import ltdAnnual from "../Assets/Images/ltd_annual.svg";
import llpComplianceDueDate from "../Assets/Images/compliance_due_dates.svg";
import rocLLPTable from "../Assets/Images/roc_llp_table.svg";
import { Button, Typography } from "antd";
import { Link } from "react-router-dom";

const TickLineWithHeading = ({ heading, text, type, list }) => (
  <>
    <div className="tick-line d-flex mt-1-5 ">
      {type === "headed" && (
        <img src={tick} alt="tick" width="20px" height="20px" />
      )}
      <Paragraph className="extra-line typography-reset-margin ml-15">
        {type === "headed" && (
          <span className="extra-line-bold text-grey-new">{heading}: </span>
        )}
        {text}
      </Paragraph>
    </div>
    {list && (
      <ul>
        {list?.map((li) => (
          <li>
            {" "}
            <Paragraph className="extra-line typography-reset-margin ml-15">
              {parse(li)}{" "}
            </Paragraph>
          </li>
        ))}
      </ul>
    )}
  </>
);
const ParagraphRenderer = (
  { heading, subHeading, array, description },
  index
) => (
  <>
    <Paragraph
      className={`more-heading ${heading !== "Types of Companies" && "mt-7"}`}
    >
      {heading && `${heading} `}
    </Paragraph>
    <Paragraph className={`more-sub-heading font-bold`}>{subHeading}</Paragraph>
    <Paragraph>{description}</Paragraph>
    {array?.map(({ type, heading, text, list }) => (
      <TickLineWithHeading
        type={type}
        heading={heading}
        text={text}
        list={list}
      />
    ))}
  </>
);

export const contactUsExtraRenderer = (name) => {
  switch (name) {
    case "companyRegistration":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Types of Companies"
            array={businessRegistrationLines.typeOfCompanies}
          />
          <ParagraphRenderer
            heading="Requirements for Company Registration"
            array={businessRegistrationLines.regRequirements}
          />
          <ParagraphRenderer
            heading="Company Registration Process"
            array={businessRegistrationLines.regProcess}
          />
        </div>
      );
    case "accounting":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Benefits of Accounting and Bookkeeping"
            description="The benefits of accounting and bookkeeping include:"
            array={accountingLines.benefits}
          />
          <ParagraphRenderer
            heading="Requirements for Company Registration"
            description="The documents required for accounting and bookkeeping services include"
            array={accountingLines.requiredDocs}
          />
        </div>
      );
    case "nidhiCompany":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Key Features:"
            array={nidhiLines.keyFeatures}
          />
          <ParagraphRenderer
            heading="Essential Licences and Registrations for Nidhi Company"
            array={nidhiLines.essentialLicense}
          />
        </div>
      );
    case "gstRegistration":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="GST Registration Threshold Limit"
            array={gstRegistrationLines.limit}
          />
          <ParagraphRenderer
            heading="Who should register for the GST Registration"
            array={gstRegistrationLines.whoRegister}
          />
          <ParagraphRenderer
            heading="Documents required for GST registration Process"
            array={gstRegistrationLines.docsRequired}
          />
          <ParagraphRenderer
            heading="Benefits of GST Registration"
            array={gstRegistrationLines.benefits}
          />
        </div>
      );
    case "gstCancellation":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Reasons for cancellation"
            array={gstCancellationLines.reasons}
          />
          <ParagraphRenderer
            heading="Steps for GST cancellation"
            array={gstCancellationLines.steps}
          />
        </div>
      );
    case "gstRefund":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Process of GST Refund"
            array={gstRefundLines.process}
          />
          <ParagraphRenderer
            heading="Timeframe for GST Refund"
            array={gstRefundLines.timeFrame}
          />
        </div>
      );
    case "showCauseNotice":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Importance of Responding to Show Cause Notices"
            array={[]}
          />
          <ParagraphRenderer heading="" array={showCauseLines.importance} />
          <ParagraphRenderer
            heading="Our Show Cause Notice Services"
            array={[]}
          />
          <ParagraphRenderer heading="" array={showCauseLines.ourServices} />
          <ParagraphRenderer
            heading="Penalties for Non-Compliance"
            description="Failure to respond to an SCN or inadequate responses can result in significant penalties under GST laws:"
            array={[]}
          />
          <ParagraphRenderer heading="" array={showCauseLines.penalties} />
        </div>
      );
    case "gstAnnualReturns":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Key Components of GSTR-9"
            decription="The GST annual return is filed using Form GSTR-9, which includes the following details:"
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            array={gstAnnualReturnsLines.keyComponents}
          />
          <ParagraphRenderer
            heading="Importance of Filing GST Annual Return"
            decription="Filing the GST annual return is not only a statutory obligation but also offers several significant benefits:"
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            array={gstAnnualReturnsLines.importance}
          />
          <ParagraphRenderer
            heading="Key Components of GSTR-9"
            decription="GSTR-9 is divided into several parts, each capturing specific details:"
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            array={gstAnnualReturnsLines.keyComponents2}
          />
          <ParagraphRenderer
            heading="Penalties for Non-Compliance"
            decription="Non-compliance with the GST annual return filing requirements can lead to significant penalties:"
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            array={gstAnnualReturnsLines.penalties}
          />
          <ParagraphRenderer
            heading="Our GST Annual Return Services"
            decription="Navigating the complexities of GST annual return filing can be challenging. Our professional services include:"
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            array={gstAnnualReturnsLines.annualReturnServices}
          />
        </div>
      );
    case "gstAppeal":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Key Stages in the GST Appeal Process"
            array={[]}
          />
          <ParagraphRenderer heading="" array={gstAppealLines.keyStage} />
          <ParagraphRenderer
            heading="Penalties for Non-Compliance"
            description="Failure to comply with GST regulations or to respond appropriately to disputes can lead to significant penalties. Some of the key penalties include:"
            array={[]}
          />
          <ParagraphRenderer heading="" array={gstAppealLines.penalties} />
          <ParagraphRenderer
            heading="Our GST Appeal Services"
            description="At Easy Works, we offer comprehensive services to support you through the GST appeal process:"
            array={[]}
          />
          <ParagraphRenderer heading="" array={gstAppealLines.ourServices} />
        </div>
      );
    case "gstRevocation":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Reasons for GST Cancellation by the GST Department"
            array={gstRevocationLines.reasons}
          />
          <ParagraphRenderer
            heading="Process of GST cancellation"
            array={gstRevocationLines.processCancellation}
          />
          <ParagraphRenderer
            heading="Process of GST Revocation"
            array={gstRevocationLines.processRevocation}
          />
        </div>
      );
    case "gstAPOB":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Importance of Registering Additional Place of Business (APOB)"
            array={gstApobLines.importance}
          />
          <ParagraphRenderer
            heading="Our GST APOB Services"
            array={gstApobLines.services}
          />
          <ParagraphRenderer
            heading="Penalties for Non-Compliance"
            array={gstApobLines.penalties}
          />
        </div>
      );
    case "gstReturnFiling":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Types of GST Returns"
            array={gstReturnsLines.types}
          />
          <ParagraphRenderer
            heading="Who needs to File GST Return?"
            array={gstReturnsLines.whoNeeds}
          />
        </div>
      );
    case "bookkeeping":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Benefits of Bookkeeping"
            array={bookkeepingLines.benefits}
          />
          <ParagraphRenderer
            heading="Documents Required for Bookkeeping"
            description="To provide our bookkeeping services, we require the following documents:"
            array={bookkeepingLines.docsRequired}
          />
        </div>
      );
    case "personalTaxFiling":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Importance Of Personal Tax Filing"
            description=""
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={personalTaxFilingLines.importance}
          />
          <ParagraphRenderer
            heading="Steps For Personal Tax Filing"
            description=""
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={personalTaxFilingLines.steps}
          />
          <ParagraphRenderer
            heading="Common Deductions and Exemptions
"
            description=""
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={personalTaxFilingLines.deductions}
          />
        </div>
      );
    case "additionalProTax":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading=""
            description=""
            array={additionalRegProTaxLines.whoNeeds}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={additionalRegProTaxLines.docsRequired}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={additionalRegProTaxLines.states}
          />
        </div>
      );
    case "additionalfssaiReg":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading=""
            description=""
            array={additionalfssaiLines.whoNeeds}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={additionalfssaiLines.types}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={additionalfssaiLines.docsRequired}
          />
          <ParagraphRenderer
            heading="FSSAI Registration Process"
            description=""
            array={additionalRegProTaxLines.regProcess}
          />
        </div>
      );
    case "udyamRegistration":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading=""
            description=""
            array={udyamLines.benefits}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={udyamLines.renewal}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={udyamLines.whoCanApply}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={udyamLines.docsRequired}
          />
        </div>
      );
    case "startupIndia":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Features of Startup India Registration"
            description=""
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={startupIndiaLines.features}
          />
          <ParagraphRenderer
            heading="Eligibility Criteria"
            description=""
            array={startupIndiaLines.eligibility}
          />
          <ParagraphRenderer
            heading="Benefits of Startup India Registration"
            description=""
            array={startupIndiaLines.benefits}
          />
          <ParagraphRenderer
            heading="Registration Process"
            description=""
            array={startupIndiaLines.regProcess}
          />
          <ParagraphRenderer
            heading="Documents Required"
            description=""
            array={startupIndiaLines.docsRequired}
          />
        </div>
      );
    case "digitalSignature":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Importance of Digital Signatures"
            description=""
            array={digitalSignatureLines.importance}
          />
          <ParagraphRenderer
            heading="Uses of Digital Signatures"
            description=""
            array={digitalSignatureLines.uses}
          />
          <ParagraphRenderer
            heading="Types of Digital Signatures"
            description=""
            array={digitalSignatureLines.types}
          />
          <ParagraphRenderer
            heading="Documents Required for Digital Signature Certificate (DSC)"
            description=""
            array={digitalSignatureLines.docsRequired}
          />
          <ParagraphRenderer
            heading="Digital Signature Certificate (DSC) Application Process"
            description=""
            array={digitalSignatureLines.applicationProcess}
          />
        </div>
      );
    case "importExportCode":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Importance of Import and Export Code"
            description=""
            array={importExportCodeLines.importance}
          />
          <ParagraphRenderer
            heading="Pre-Requisites for Applying for IEC"
            description=""
            array={importExportCodeLines.prerequisite}
          />
          <ParagraphRenderer
            heading="Nature of Firms Obtaining an IEC"
            description=""
            array={importExportCodeLines.natureOfFirms}
          />
          <ParagraphRenderer
            heading="Validity Period"
            description=""
            array={importExportCodeLines.validity}
          />
          <ParagraphRenderer
            heading="Application Process"
            description=""
            array={importExportCodeLines.applicationProcess}
          />
        </div>
      );
    case "additionalTradeLicense":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="What is a Trade License?"
            description=""
            array={additionalTradeLines.whatIs}
          />
          <ParagraphRenderer
            heading="Why is a Trade License Necessary?"
            description=""
            array={additionalTradeLines.whyNecassary}
          />
          <ParagraphRenderer
            heading="Main Objectives of a Trade License:"
            description=""
            array={additionalTradeLines.mainObjectives}
          />
          <ParagraphRenderer
            heading="Who Issues a Trade License?"
            description=""
            array={additionalTradeLines.whoIssues}
          />
          <ParagraphRenderer
            heading="Different Categories of Trade License"
            description=""
            array={additionalTradeLines.differentCategories}
          />
          <ParagraphRenderer
            heading="Eligibility and Application Process"
            description=""
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={additionalTradeLines.eligibility}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={additionalTradeLines.applicationProcess}
          />
          <ParagraphRenderer
            heading="Documents Required for Application"
            description=""
            array={additionalTradeLines.docsRequired}
          />
          <ParagraphRenderer
            heading="Trade License Fees and Processing Time"
            description=""
            array={additionalTradeLines.licenseFeenTime}
          />
          <ParagraphRenderer
            heading="Renewal and Status Check"
            description=""
            array={additionalTradeLines.renewalCheck}
          />
        </div>
      );
    case "form16":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Importance of Form 16"
            description=""
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={form16Lines.importance}
          />
          <ParagraphRenderer
            heading="Components of Form 16"
            description=""
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={form16Lines.partA}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={form16Lines.partB}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={form16Lines.howToUse}
          />
        </div>
      );
    case "tdsFiling":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Importance of TDS Filing"
            description=""
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={tdsFilingLines.importance}
          />
          <ParagraphRenderer
            heading="Steps for TDS Filing"
            description=""
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={tdsFilingLines.steps}
          />
          <ParagraphRenderer
            heading="Penalties for Non-Compliance"
            description=""
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={tdsFilingLines.penalties}
          />
        </div>
      );
    case "businessTaxFiling":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Importance of Business Tax Filing"
            description=""
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={businessTaxFilingLines.importance}
          />
          <ParagraphRenderer
            heading="Steps for Business Tax Filing"
            description=""
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={businessTaxFilingLines.steps}
          />
          <ParagraphRenderer
            heading="Penalties for Non-Compliance"
            description=""
            array={[]}
          />
          <ParagraphRenderer
            heading=""
            description=""
            array={businessTaxFilingLines.penalties}
          />
        </div>
      );
    case "llpClosure":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Types of Closure"
            array={llpClosureLines.types}
          />
          <ParagraphRenderer heading="Voluntary Closure:" array={[]} />
          {/* <ParagraphRenderer heading="Company Closure" array={[]} /> */}
          <ParagraphRenderer
            subHeading="Company Closure"
            array={llpClosureLines.reasons}
          />
          <ParagraphRenderer
            heading=""
            array={llpClosureLines.stepsCompanyClosure}
          />
          {/* <ParagraphRenderer heading="LLP Closure" array={[]} /> */}
          <ParagraphRenderer
            subHeading="LLP Closure"
            array={llpClosureLines.llpClosureReasons}
          />
          <ParagraphRenderer
            heading=""
            array={llpClosureLines.llpClosureSteps}
          />
          <ParagraphRenderer heading="Compulsory Closure:" array={[]} />
          {/* <ParagraphRenderer heading="Company/LLP Closure by ROC" array={[]} /> */}
          <ParagraphRenderer
            subHeading="Company/LLP Closure by ROC"
            array={llpClosureLines.compulsoryClosureReason}
          />
          <ParagraphRenderer heading="" array={llpClosureLines.process} />
        </div>
      );
    case "payrollProcessing":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Benefits of Payroll Processing"
            array={payrollLines.benefits}
          />
          <ParagraphRenderer
            heading="Documents Required for Payroll Processing Services"
            array={payrollLines.docsRequired}
          />
        </div>
      );
    case "amazonEcommerce":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading=""
            array={amazonEcommerceLines.importance}
          />
          <ParagraphRenderer heading="" array={amazonEcommerceLines.steps} />
          <ParagraphRenderer heading="" array={amazonEcommerceLines.tools} />
          <ParagraphRenderer heading="" array={amazonEcommerceLines.services} />
          <ParagraphRenderer heading="" array={amazonEcommerceLines.benefits} />
        </div>
      );
    case "gstFiling":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Types of GST Returns"
            array={gstFilingLines.types}
          />
          <ParagraphRenderer
            heading="Who Needs to File GST Returns?"
            array={gstFilingLines.whoNeeds}
          />
        </div>
      );
    case "ltdLiability":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Key Features:"
            array={ltdLiabilityLines.keyFeatures}
          />
          <ParagraphRenderer
            heading="Essential Licences and Registrations for LLP"
            array={ltdLiabilityLines.essentialLicense}
          />
        </div>
      );
    case "section8":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Key Features"
            array={section8Lines.keyFeatures}
          />
          <ParagraphRenderer
            heading="Essential Licences and Registrations for Section 8 Company"
            array={section8Lines.essentialLicense}
          />
        </div>
      );
    case "proTaxReg":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Who Needs to Register For Professional Tax Registration"
            array={proTaxLines.whoNeeds}
          />
          <ParagraphRenderer
            heading="Documents Required"
            array={proTaxLines.docsRequired}
          />
          <ParagraphRenderer
            heading="Professional tax is imposed only in following States"
            array={proTaxLines.states}
          />
        </div>
      );
    case "tradeLicense":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="What is a Trade License?"
            description={tradeLicenseLines.whatIs}
          />
          <ParagraphRenderer
            heading="Why is a Trade License Necessary?"
            description={tradeLicenseLines.whyTradeLicense}
          />
          <ParagraphRenderer
            heading="Main Objectives of a Trade License"
            array={tradeLicenseLines.objectives}
          />
          <ParagraphRenderer
            heading="Who Issues a Trade License?"
            description={tradeLicenseLines.whoIssues}
          />
          <ParagraphRenderer
            heading="Different Categories of Trade License"
            array={tradeLicenseLines.categories}
          />
          <ParagraphRenderer
            heading="Eligibility and Application Process"
            description={tradeLicenseLines.eligibility}
          />
          <ParagraphRenderer
            heading="Eligibility and Application Process"
            description={tradeLicenseLines.howToApply}
          />
          <ParagraphRenderer
            heading="Documents Required for Application"
            array={tradeLicenseLines.docsRequired}
          />
          <ParagraphRenderer
            heading=""
            description={tradeLicenseLines.feesVaryText}
          />
          <ParagraphRenderer
            heading="Renewal and Status Check"
            description={tradeLicenseLines.renewalCheck}
          />
        </div>
      );
    case "fssaiRegistration":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Who Needs FSSAI Registration?"
            description={fssaiRegLines.whoNeeds}
          />
          <ParagraphRenderer
            heading="Who issues FSSAI?"
            description={fssaiRegLines.whoIssues}
          />
          <ParagraphRenderer
            heading="Types of FSSAI Registration-"
            array={fssaiRegLines.types}
          />
          <ParagraphRenderer
            heading="Documents Required"
            array={fssaiRegLines.docsRequired}
          />
          <ParagraphRenderer
            heading="FSSAI Registration Process"
            array={fssaiRegLines.regProcess}
          />
        </div>
      );
    case "flipkartEComm":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Importance of Payment Reconciliation"
            array={flipkartEcommerceLines.importance}
          />
          <ParagraphRenderer
            heading="Our Flipkart E-Commerce Payment Reconciliation Services"
            array={flipkartEcommerceLines.services}
          />
          <ParagraphRenderer
            heading="Benefits of Using Easy Works for Payment Reconciliation"
            array={flipkartEcommerceLines.benefits}
          />
        </div>
      );
    case "llpCompliance":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Understanding ROC Compliance"
            description="ROC compliance refers to the set of regulations and guidelines that Limited Liability Partnerships must follow as per the LLP Act, 2008. These compliances are essential to maintain the LLP’s good standing and avoid penalties. They involve regular filings and submissions to the Registrar of Companies to ensure transparency and accountability in business operations.
"
          />
          <ParagraphRenderer
            heading="Key ROC Compliance Requirements"
            array={llcComplianceLines.importance}
          />
        </div>
      );
    case "rocPvtLtd":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Understanding ROC Compliance"
            description="ROC compliance refers to the set of regulations and guidelines that Private Limited Companies must follow as per the Companies Act, 2013. These compliances are essential to maintain the company’s good standing and avoid penalties. They involve regular filings and submissions to the Registrar of Companies to ensure transparency and accountability in business operations."
          />
          <ParagraphRenderer
            heading="Key ROC Compliance Requirements"
            array={rocCompliancePVTLTDLines.requirements}
          />
          {/* <ParagraphRenderer
            heading="Steps to File DIR-3 KYC"
            array={rocCompliancePVTLTDLines.steps}
          /> */}
        </div>
      );
    case "changeInShareCapital":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Types of Changes in Share Capital"
            array={changeInShareCapitalLines.types}
          />
          <ParagraphRenderer
            heading="Steps Involved in Changing Share Capital"
            array={changeInShareCapitalLines.steps}
          />
          <ParagraphRenderer
            heading="Documents Required"
            array={changeInShareCapitalLines.docsRequired}
          />
        </div>
      );
    case "regOfficeAddressChange":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading=""
            array={regOfficeAddressChangeLines.reasons}
          />
          <ParagraphRenderer
            heading="Types of Changes"
            array={regOfficeAddressChangeLines.types}
          />
          <ParagraphRenderer
            heading="Procedure for Change of Registered Office Address:"
            array={[]}
          />

          <ParagraphRenderer
            heading="Change Within the Same City/Town/Village"
            array={regOfficeAddressChangeLines.addressSteps}
          />
          <ParagraphRenderer
            heading=""
            array={regOfficeAddressChangeLines.sameCityDocs}
          />
          <ParagraphRenderer
            heading="Change Within the Same State but Different City/Town/Village"
            array={regOfficeAddressChangeLines.sameCityDifferentTownSteps}
          />
          <ParagraphRenderer
            heading=""
            array={regOfficeAddressChangeLines.sameCityDifferentTownDocs}
          />
          <ParagraphRenderer
            heading="Change From One State to Another"
            array={regOfficeAddressChangeLines.oneStateToAnotherSteps}
          />
          <ParagraphRenderer
            heading=""
            array={regOfficeAddressChangeLines.oneStateToAnotherDocs}
          />
        </div>
      );
    case "addRemoveDirectors":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Adding Directors"
            array={addRemoveDirectorLines.reasons}
          />
          <ParagraphRenderer heading="" array={addRemoveDirectorLines.steps} />
          <ParagraphRenderer
            heading=""
            array={addRemoveDirectorLines.docsRequired}
          />

          <ParagraphRenderer
            heading="Removing Directors"
            array={addRemoveDirectorLines.removeSteps}
          />
          <ParagraphRenderer
            heading=""
            array={addRemoveDirectorLines.removeReasons}
          />
          <ParagraphRenderer
            heading=""
            array={addRemoveDirectorLines.removeDocs}
          />
        </div>
      );
    case "dirKYC":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Importance of DIR-3 KYC"
            array={rocDIRLines.importance}
          />
          <ParagraphRenderer
            heading="Steps to File DIR-3 KYC"
            array={rocDIRLines.steps}
          />
          <ParagraphRenderer
            heading="Penalties for Non-Compliance"
            array={rocDIRLines.penalties}
          />
        </div>
      );
    case "onePerson":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Key Features:"
            array={onePersonLines.keyFeatures}
          />
          <ParagraphRenderer
            heading="Essential Licences and Registrations for One Person Company"
            array={onePersonLines.incorporationEssential}
          />
          <ParagraphRenderer heading="" array={onePersonLines.bankEssential} />
          <ParagraphRenderer heading="" array={onePersonLines.gstEssential} />
          <ParagraphRenderer
            heading=""
            array={onePersonLines.proTaxEssential}
          />
          <ParagraphRenderer heading="" array={onePersonLines.shopEssential} />
          <ParagraphRenderer heading="" array={onePersonLines.msmeEssential} />
          <ParagraphRenderer heading="" array={onePersonLines.tradeEssential} />
          <ParagraphRenderer heading="" array={onePersonLines.fssaiEssential} />
          <ParagraphRenderer
            heading=""
            array={onePersonLines.importExportEssential}
          />
        </div>
      );
    case "pvtLtd":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Key Features:"
            array={pvtLtdLines.keyFeatures}
          />
          <ParagraphRenderer
            heading="Essential Licences and Registrations for Private Limited Company"
            array={pvtLtdLines.incorporationEssential}
          />
          <ParagraphRenderer heading="" array={pvtLtdLines.bankEssential} />
          <ParagraphRenderer heading="" array={pvtLtdLines.gstEssential} />
          <ParagraphRenderer heading="" array={pvtLtdLines.proTaxEssential} />
          <ParagraphRenderer heading="" array={pvtLtdLines.shopsEssential} />
          <ParagraphRenderer heading="" array={pvtLtdLines.msmeEssential} />
          <ParagraphRenderer heading="" array={pvtLtdLines.tradeEssential} />
          <ParagraphRenderer heading="" array={pvtLtdLines.fssaiEssential} />
          <ParagraphRenderer heading="" array={pvtLtdLines.importEssential} />
        </div>
      );
    case "partnership":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Key Features:"
            array={partnershipLines.keyFeatures}
          />
          <ParagraphRenderer
            heading="Essential Licences and Registrations for Partnership"
            array={partnershipLines.deedEssential}
          />
          <ParagraphRenderer heading="" array={partnershipLines.panEssential} />
          <ParagraphRenderer
            heading=""
            array={partnershipLines.bankEssential}
          />
          <ParagraphRenderer heading="" array={partnershipLines.gstEssential} />
          <ParagraphRenderer
            heading=""
            array={partnershipLines.proTaxEssential}
          />
          <ParagraphRenderer
            heading=""
            array={partnershipLines.shopsEssentials}
          />
          <ParagraphRenderer
            heading=""
            array={partnershipLines.msmeEssentials}
          />
          <ParagraphRenderer
            heading=""
            array={partnershipLines.licenseEssentials}
          />
          <ParagraphRenderer
            heading=""
            array={partnershipLines.fssaiEssentials}
          />
          <ParagraphRenderer
            heading=""
            array={partnershipLines.importEssentials}
          />
        </div>
      );
    case "proprietorship":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Key Features:"
            array={proprietorshipLines.keyFeatures}
          />
          <ParagraphRenderer
            heading="Essential Licences and Registrations for Proprietorships"
            array={proprietorshipLines.gstRegEssential}
          />
          <ParagraphRenderer
            heading=""
            array={proprietorshipLines.bankAccountEssential}
          />
          <ParagraphRenderer
            heading=""
            array={proprietorshipLines.proTaxEssential}
          />
          <ParagraphRenderer
            heading=""
            array={proprietorshipLines.shopsEstablishment}
          />
          <ParagraphRenderer
            heading=""
            array={proprietorshipLines.msmeEssential}
          />
          <ParagraphRenderer
            heading=""
            array={proprietorshipLines.tradeLicenseEssential}
          />
          <ParagraphRenderer
            heading=""
            array={proprietorshipLines.fssaiLicenseEssential}
          />
          <ParagraphRenderer
            heading=""
            array={proprietorshipLines.importEssentials}
          />
        </div>
      );
    case "rocCompliance":
      return (
        <div className="extra-detail-container">
          <ParagraphRenderer
            heading="Importance of ROC compliance"
            description="ROC compliance is crucial because of the following reasons:"
            array={rocLines.importance}
          />
          <ParagraphRenderer
            heading="Compliance Guidelines for Private Limited Companies"
            description="Compliance is vital for the smooth operation of private limited companies in India. It encompasses adherence to the Companies Act 2013 and obligations to the Registrar of Companies (RoC). These regulations cover various aspects, including the appointment, remuneration, and retirement of directors, as well as the conduct of board and shareholder meetings.
Understanding ROC Compliance for Private Limited Companies
ROC compliance is mandatory for all private limited companies, irrespective of turnover or capital amount. It can be categorized into:
"
            array={rocLines.guidelines}
          />
          <ParagraphRenderer
            heading="Manage Company Compliance with Easy Works"
            noColun
            description=""
          />
          <ParagraphRenderer
            subHeading="Efficiently Meeting Company Compliance Needs"
            description="Compliance with regulatory requirements is crucial for the smooth functioning of private limited companies. At IndiaFilings, we provide comprehensive support to ensure that companies meet their compliance obligations effectively and seamlessly. Here's how we can assist:"
          />
          <ParagraphRenderer
            subHeading="Dedicated Advisor"
            description="Each company is assigned a dedicated Compliance Manager who serves as a single point of contact. Your Compliance Manager is available to assist you with all compliance-related matters, providing personalized support whenever needed."
          />
          <ParagraphRenderer
            subHeading="Accounting Services"
            description="Maintaining accounts and preparing financial statements is a mandatory requirement for all companies. Our Compliance Manager will help your company maintain accurate accounts and prepare financial statements at the end of each financial year."
          />
          <ParagraphRenderer
            subHeading="Secretarial Services"
            description="Companies are required to conduct board meetings, hold annual general meetings, and prepare various secretarial reports each financial year. Our Compliance Manager will assist in preparing meeting minutes, Directors' and Annual Reports, ensuring compliance with statutory requirements."
          />
          <ParagraphRenderer
            subHeading="MCA Annual Return Filing"
            description="Annual General Meetings must be held within six months from the end of the financial year, with MCA annual returns filed by September 30th. Our Compliance Manager will handle all documentation and file your company’s MCA annual return efficiently and on time."
          />
          <ParagraphRenderer
            subHeading="Income Tax Return Filing"
            description="Every company, regardless of income or profitability, must file income tax returns annually. Our Compliance Manager will prepare all necessary documents and ensure timely filing of your company’s income tax return."
          />
        </div>
      );
    default:
      return;
  }
};

export const serviceRenderer = (pathname) => {
  switch (pathname) {
    case "/startup-compliance":
      return <StartupService />;
    case "/vpob-apob":
      return <VPOBService />;
    case "/gst-advisory":
      return <GSTService />;
    case "/fixed-desk":
      return <FixedDeskServices />;
    default:
      return;
  }
};

export const fancyUlTextRenderer = (src, className, text, color) => (
  <span className={`stylish-text inline-block ${className}`} style={{ color }}>
    {text}
    <ReactSVG
      width="100%"
      src={src}
      style={{ width: "100%", marginBottom: "8px", height: "6px" }}
      beforeInjection={(svg) => {
        svg.setAttribute("width", "100%");
        // svgClass && svg.setAttribute("className", svgClass);
        // svg.setAttribute("stroke", color);
      }}
      className="fancy-text"
    />
  </span>
);

export const renderInternalTable = (page) => {
  switch (page) {
    case "proprietorship":
      return true;
    case "partnership":
      return true;
    case "ltdLiability":
      return true;
    case "pvtLtd":
      return true;
    default:
      return false;
  }
};
export const renderInternalTable2 = (page) => {
  console.log("page 2", page);
  switch (page) {
    case "proprietorship":
      return (
        <>
          <div className="internal-page-table-container">
            <Title level={2} className="gst-services-heading ">
              Annual Compliances
            </Title>
            <div className="internal-table-wrapper">
              <img
                src={complianceTable}
                className="internal-table"
                alt="internal-table"
              />
            </div>
          </div>
          <div className="internal-page-table-container">
            <Title level={2} className="gst-services-heading ">
              Licenses and Registrations
            </Title>
            <div className="internal-table-wrapper">
              <img
                src={licenseTable}
                className="internal-table"
                alt="internal-table"
              />
            </div>
          </div>
        </>
      );
    case "partnership":
      return (
        <>
          <div className="internal-page-table-container">
            <Title level={2} className="gst-services-heading ">
              Annual Compliances
            </Title>
            <div className="internal-table-wrapper">
              <img
                src={partnerCompliance}
                className="internal-table"
                alt="internal-table"
              />
            </div>
          </div>
          <div className="internal-page-table-container">
            <Title level={2} className="gst-services-heading ">
              Licenses and Registrations
            </Title>
            <div className="internal-table-wrapper">
              <img
                src={partnerLicense}
                className="internal-table"
                alt="internal-table"
              />
            </div>
          </div>
        </>
      );
    case "onePerson":
      return (
        <>
          <div className="internal-page-table-container">
            <Title level={2} className="gst-services-heading ">
              Annual Compliances
            </Title>
            <div className="internal-table-wrapper">
              <img
                src={oneCompliance}
                className="internal-table"
                alt="internal-table"
              />
            </div>
          </div>
          <div className="internal-page-table-container">
            <Title level={2} className="gst-services-heading ">
              Section 8 Company vs One Person Company
            </Title>
            <div className="internal-table-wrapper">
              <img
                src={oneComparo}
                className="internal-table"
                alt="internal-table"
              />
            </div>
          </div>
        </>
      );
    case "pvtLtd":
      return (
        <div className="internal-page-table-container">
          <Title
            level={2}
            className="annual-compliance-heading "
            style={{ width: "70vw", textAlign: "center" }}
          >
            Compliance Requirements for Private Limited Company
          </Title>
          <p className="annual-compliance-description">
            To ensure smooth operations and avoid penalties, Private Limited
            Companies must adhere to various compliance requirements as mandated
            by the Ministry of Corporate Affairs (MCA). Here’s a detailed look
            at these compliance requirements:
          </p>
          {/* <Title level={2} className="annual-compliance-heading ">
            Annual Compliances
          </Title> */}
          <div className="internal-table-wrapper">
            <img
              src={pvtCompliance}
              className="internal-table"
              alt="internal-table"
            />
          </div>
        </div>
      );
    case "llpCompliance":
      return (
        <div className="internal-page-table-container">
          <Title level={2} className="gst-services-heading ">
            Compliance Due Dates Table
          </Title>
          <div className="internal-table-wrapper">
            <img
              src={rocLLPTable}
              className="internal-table"
              alt="internal-table"
            />
          </div>
        </div>
      );
    case "rocPvtLtd":
      return (
        <div className="internal-page-table-container">
          <Title level={2} className="gst-services-heading ">
            Compliance Due Dates Table
          </Title>
          <div className="internal-table-wrapper">
            <img
              src={llpComplianceDueDate}
              className="internal-table"
              alt="internal-table"
            />
          </div>
        </div>
      );
    case "section8":
      return (
        <>
          <div className="internal-page-table-container">
            <Title level={2} className="gst-services-heading ">
              Annual Compliances
            </Title>
            <div className="internal-table-wrapper">
              <img
                src={section8Compliance}
                className="internal-table"
                alt="internal-table"
              />
            </div>
          </div>
          <div className="internal-page-table-container">
            <Title level={2} className="gst-services-heading ">
              Section 8 Company vs One Person Company
            </Title>
            <div className="internal-table-wrapper">
              <img
                src={oneComparo}
                className="internal-table"
                alt="internal-table"
              />
            </div>
          </div>
        </>
      );
    case "nidhiCompany":
      return (
        <>
          <div className="internal-page-table-container">
            <Title level={2} className="gst-services-heading ">
              Annual Compliances
            </Title>
            <div className="internal-table-wrapper">
              <img
                src={nidhiCompliance}
                className="internal-table"
                alt="internal-table"
              />
            </div>
          </div>
          <div className="internal-page-table-container">
            <Title level={2} className="gst-services-heading ">
              Section 8 Company vs One Person Company
            </Title>
            <div className="internal-table-wrapper">
              <img
                src={oneComparo}
                className="internal-table"
                alt="internal-table"
              />
            </div>
          </div>
        </>
      );
    case "ltdLiability":
      return (
        <div className="internal-page-table-container">
          <Title level={2} className="gst-services-heading ">
            Annual Compliances
          </Title>
          <div className="internal-table-wrapper">
            <img
              src={ltdAnnual}
              className="internal-table"
              alt="internal-table"
            />
          </div>
        </div>
      );

    default:
      return false;
  }
};

export const selectedBookingTabRender = (
  selectedStep,
  form,
  handleFormFinish,
  setIsECommerce,
  isECommerce
) => {
  switch (selectedStep) {
    case 0:
      return <Plans />;
    case 1:
      return <Tenure />;
    case 2:
      return <AddOns />;
    case 3:
      return (
        <PaymentDetail
          form={form}
          handleFormFinish={handleFormFinish}
          setIsECommerce={setIsECommerce}
          isECommerce={isECommerce}
        />
      );
    default:
      return <Plans />;
  }
};
export const selectedBooking3TabRender = (
  selectedStep,
  form,
  handleFormFinish,
  setIsECommerce,
  isECommerce
) => {
  switch (selectedStep) {
    case 0:
      return <Plans />;

    case 1:
      return <AddOns />;
    case 2:
      return (
        <PaymentDetail
          form={form}
          handleFormFinish={handleFormFinish}
          isECommerce={isECommerce}
          setIsECommerce={setIsECommerce}
        />
      );
    default:
      return <Plans />;
  }
};

export const bookingErrRenderer = (
  type,
  closeDialog,
  userName,
  businessId,
  formData,
  closeDialogOnly
) => {
  switch (type) {
    case "BUSINESS_ALREADY_EXISTS":
      return (
        <div className="err-modal-body text-center">
          <Typography className="text-pure-black font-20 font-600">
            Welcome back!
          </Typography>
          <Typography className="text-14 font-grey-2 err-modal-middle-text">
            Hey {userName}, We’ve found your previous bookings with us. The
            company name matches with one of your existing businesses. Do you
            want to continue the booking under the same business?
          </Typography>
          <Button
            className="contained-primary fullwidth"
            onClick={() => closeDialog(businessId, formData)}
          >
            Continue
          </Button>
        </div>
      );
    case "NEW_COMPANY_REQUIRED":
      return (
        <div className="err-modal-body text-center">
          <Typography className="text-pure-black font-20 font-600">
            Welcome back!
          </Typography>
          <Typography className="text-14 font-grey-2 err-modal-middle-text">
            Hey {userName}, We’ve found your previous bookings with us. The
            company name does not match with any of your existing businesses. Do
            you want to continue and register this as a new company with us?
          </Typography>
          <Button
            className="contained-primary fullwidth"
            onClick={() => closeDialog(false, formData)}
          >
            Continue
          </Button>
        </div>
      );
    case "COMPANY_NAME_ALREADY_EXISTS":
      return (
        <div className="err-modal-body">
          <img src={declined} alt="declined" />
          <Typography className="payment-modal-title">
            Invalid Company Name ❌
          </Typography>
          <Typography className="payment-modal-description font-grey-2 ">
            This company name has already been registered with us under a
            different individual. Please use a different company name or you can
            contact us for further information
          </Typography>
          <div className="payment-declined-dialog-footer">
            <Link to="/contact-us-basic" className="fullwidth">
              <Button
                // type="outlined"
                className="fullwidth d-flex align-center justify-center err-contact-cta"
                onClick={closeDialogOnly}
              >
                <ReactSVG
                  src={headphones}
                  alt="contact"
                  style={{ marginRight: "8px", height: "24px" }}
                />
                Contact Us
              </Button>
            </Link>
          </div>
        </div>
      );
    case "INVALID_GST_NUMBER":
      return (
        <div className="err-modal-body">
          <img src={declined} alt="declined" />
          <Typography className="payment-modal-title">
            Invalid GST Number ❌
          </Typography>
          <Typography className="payment-modal-description font-grey-2 ">
            This is an invalid GST number. Please recheck and try again with the
            valid GST number for this company
          </Typography>
          <div className="payment-declined-dialog-footer">
            <Link to="/contact-us-basic" className="fullwidth">
              <Button
                // type="outlined"
                className="fullwidth d-flex align-center justify-center err-contact-cta"
                onClick={closeDialogOnly}
              >
                <ReactSVG
                  src={headphones}
                  alt="contact"
                  style={{ marginRight: "8px", height: "24px" }}
                />
                Contact Us
              </Button>
            </Link>
          </div>
        </div>
      );
    default:
      return "";
  }
};
