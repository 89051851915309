import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { privacyRenderer } from '../../Utils/helpers';
import './index.scss';

const Privacy = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);
  return <div className="privacy-container">{privacyRenderer(pathname)}</div>;
};

export default Privacy;
