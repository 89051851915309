import React, { useEffect } from "react";
import ClientSay from "../../Components/ClientSay";
import GetStarted from "../../Components/GetStarted";
import "./index.scss";
import Banner from "./Components/Banner";
import OurServices from "./Components/OurServices";
import GSTServices from "./Components/GSTServices";
import { autoScroll } from "../../Utils/helpers";
import { useLocation } from "react-router-dom";
import { serviceRenderer } from "../../Utils/renderer";
import TrustedBy from "../../Components/TrustedBy";
import {
  customerLogos,
  fixedDesk_Faq,
  sellerLogos,
} from "../../Utils/staticsData";
import FAQ from "../../Components/FAQ";
// import {
//   customerLogos,
//   sellerLogos,
// } from "../../../../Utils/staticsData";

const InternalPage = () => {
  const href = window.location.href.substring(window.location.href);
  const { pathname } = useLocation();
  useEffect(() => {
    autoScroll();
  }, [href]);

  return (
    <div className="internal-page-container">
      <Banner />
      <div className="rest-trusted">
        {" "}
        <TrustedBy
          companyLogos={sellerLogos}
          text="Trusted and used by 200+ Customers"
          customerLogos={customerLogos}
        />
      </div>
      {serviceRenderer(pathname)}
      {/* <OurServices /> */}
      <ClientSay />
      <GSTServices />
      {pathname === "/fixed-desk" && <FAQ faq_Config={fixedDesk_Faq} />}
      <GetStarted />
    </div>
  );
};

export default InternalPage;
