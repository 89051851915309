import { Button, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import rtRrw from "../../Assets/Icons/chevron-right-orange.svg";
import moment from "moment/moment";
import "./index.scss";

const BlogCard = ({ thumbnailUrl, title, createdAt, id, singleBlog }) => {
  return (
    <div className={`blog-card-container ${!singleBlog && "d-flex"}`}>
      <div
        className={`content-img ${
          singleBlog ? "content-imgA" : "content-imgB"
        }`}
        style={{ backgroundImage: `url(${thumbnailUrl})` }}
      />

      <div className={!singleBlog && "ml-20"}>
        <Typography className="font-grey-2 blog-card-date">
          {moment(createdAt).format("ll")}
        </Typography>
        <Typography className="blog-preview-card-title">{title}</Typography>

        <Link to={`/blogs/view/${id}`}>
          <Button
            type="text"
            icon={<img src={rtRrw} width="24px" alt="rt-arr" />}
            iconPosition="end"
            size="large"
            className="preview-live-btn"
          >
            Read more
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
