import React from "react";
import "./index.scss";
import { Button, Typography } from "antd";
import arrowRight from "../../../../Assets/Images/thin-arr-rt.svg";
import fingerImage from "../../../../Assets/Images/fingerTips.svg";
import fancyLine from "../../../../Assets/Icons/styled-orange.svg";
import { fancyUlTextRenderer } from "../../../../Utils/renderer";
import { Link } from "react-router-dom";

const FingerTips = () => {
  return (
    <div className="fingertips-container">
      <Typography className="plan-price fingertip-heading">
        Everything at your{" "}
        {fancyUlTextRenderer(
          fancyLine,
          "bold",
          "fingertips",
          "#D77D13",
          "orange-style"
        )}
      </Typography>
      <Typography className="stat-figure-text-2 fingertip-text">
        Streamline your communication and eliminate the hassle of calls, texts,
        and delayed responses. One stop solution for all your compliance needs.
      </Typography>
      <Link to="/contact-us-basic">
        <Button
          className="contained-primary fingertip-cta"
          icon={<img src={arrowRight} width="100%" alt="banner-img" />}
        >
          Get a Quote
        </Button>
      </Link>
      <img src={fingerImage} alt="finger" className="finger-img" />
    </div>
  );
};

export default FingerTips;
