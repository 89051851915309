import { Card } from "antd";
import React from "react";
import Typography from "antd/es/typography/Typography";
import { Link } from "react-router-dom";
import arrowRight from "../../../Assets/Icons/chevron-right-orange.svg";

const StartupServiceCard = ({ title, serviceId, links, icon }) => {
  return (
    <Card className="startup-service-card">
      <div className="service-card-header">
        <img src={icon} alt="building" width="20px" />
        <Typography className="service-card-title">{title}</Typography>
      </div>
      <div className="service-card-body">
        {links.map(({ label, description, faq_Config, planId, name }) => (
          <div>
            {console.log("serviceId", serviceId, planId)}
            <Link
              className="service-card-link"
              to="/contact-us"
              state={{
                label,
                faq: JSON.stringify(faq_Config),
                description,
                name,
                serviceId,
                planId,
                dontCall: true,
              }}
            >
              {label}
              <img
                src={arrowRight}
                alt="rt-arrow"
                className="service-link-arrow"
              />
            </Link>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default StartupServiceCard;
