import { Button, Checkbox, Form, Grid, Input, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import dropdown from "../../Assets/Icons/select-drpdwn.svg";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import "./index.scss";
import TextArea from "antd/es/input/TextArea";
import Body2 from "../ContactUs/Body2";
import formBg from "../../Assets/Images/new-form-bg.png";
import { useDispatch, useSelector } from "react-redux";
import { createLead } from "../../Redux/Slices/PlanSlice";
import LeadGenerated from "../../Components/Dialog/LeadCreated";

const ContactUsBasic = () => {
  const { updateLoading } = useSelector((state) => state.planSlice);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const [isECommerce, setIsECommerce] = useState(false);
  const handleCheck = () => setIsECommerce(!isECommerce);
  const dispatch = useDispatch();
  const handleFormFinish = (values) =>
    dispatch(
      createLead({
        values: {
          companyName: values.companyName,
          clientName: values.clientName,
          service: values.service,
          contactDetails: values.email + "," + values.number,
          message: values.message,
          isECommerce,
        },
      })
    );
  return (
    <div className="contact-us-basic-container">
      <div className="contact-us-basic-banner">
        <div className="contact-us-basic-banner-left">
          <Title
            level={1}
            className="compliance-banner-middle-text bold typography-reset-margin"
          >
            Contact us
          </Title>

          <Paragraph className="text-center banner-description font-300 text-16 text-black  typography-reset-margin">
            Our accounting services cover a wide range of tasks, including
            bookkeeping, financial statement preparation, budgeting, payroll
            processing, and tax planning. We ensure that your financial records
            are accurate, up-to-date, and compliant with relevant accounting
            standards and regulations. By outsourcing your accounting needs to
            us, you can focus on core business activities while having peace of
            mind knowing that your finances are in good hands.
          </Paragraph>
        </div>
        <div className="contact-us-basic-banner-right">
          <div className=" banner-form-wrapper contact-us-2-form-wrapper get-started-form-wrapper">
            <img src={formBg} alt="formBg" className="internal-form-bg" />
            <div className="contact-us-form contact-us-basic-form">
              <Form
                onFinish={handleFormFinish}
                initialValues={{
                  companyName: "",
                  clientName: "",
                  email: "",
                  number: "",
                  service: "VPOB & APOB",
                  message: "",
                }}
              >
                <Form.Item
                  // label=""
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter company name",
                    },
                  ]}
                  className="banner-field-form-item"
                >
                  <Input placeholder="Company name" size="large" />
                </Form.Item>
                <Form.Item
                  // label=""
                  name="clientName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name",
                    },
                  ]}
                  className="banner-field-form-item"
                >
                  <Input placeholder="Your name" size="large" />
                </Form.Item>
                <Form.Item
                  // label=""
                  name="number"
                  className="banner-field-form-item"
                  rules={[
                    {
                      pattern:
                        /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g,
                      message: "Please enter valid number",
                    },
                    {
                      required: true,
                      message: "Please enter your contact number",
                    },
                  ]}
                >
                  <Input placeholder="Phone Number" size="large" />
                </Form.Item>
                <Form.Item
                  // label=""
                  name="email"
                  rules={[
                    {
                      pattern:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Enter valid email only",
                    },
                    {
                      required: true,
                      message: "Please enter your email id",
                    },
                  ]}
                  className="banner-field-form-item"
                >
                  <Input placeholder="Email ID" size="large" />
                </Form.Item>
                <Form.Item
                  // label=""
                  name="service"
                  rules={[]}
                  // style={{ flex: 1, marginRight: "12px", width: "100%" }}
                  className="banner-field-form-item"
                >
                  <Select
                    defaultValue="vpobAndApob"
                    size="large"
                    suffixIcon={<img src={dropdown} width="24px" alt="ddwn" />}
                    options={[
                      {
                        value: "VPOB & APOB",
                        label: "VPOB & APOB",
                      },
                      {
                        value: "Startup and Compliance",
                        label: "Startup and Compliance",
                      },
                      {
                        value: "GST Advisory",
                        label: "GST Advisory",
                      },
                      {
                        value: "Fixed Desk",
                        label: "Fixed Desk",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  // label=""
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your enquiry",
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <TextArea placeholder="Your enquiry" size="large" rows={4} />
                </Form.Item>
                <div className="form-ecom-chkbox-wrapper-2">
                  {" "}
                  <Checkbox
                    size="large"
                    checked={isECommerce}
                    onChange={handleCheck}
                  />
                  <Typography className="form-ecom-text">
                    Are you an E-Commerce seller?
                  </Typography>
                </div>
                <Form.Item
                  wrapperCol={{
                    // offset: 8,
                    span: 32,
                  }}
                  // style={{ width: "100%" }}
                  className="banner-cta-form-item"
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", padding: "12px, 24px, 12px, 24p" }}
                    className="contained-primary home-bottom-form-cta"
                    size="large"
                    disabled={updateLoading}
                  >
                    Contact Us Now
                  </Button>
                </Form.Item>
                <br />
              </Form>
            </div>
            {/* <img src={human} alt="human" className="human-image" /> */}
          </div>
        </div>
      </div>
      <Body2 />
      <LeadGenerated />
    </div>
  );
};

export default ContactUsBasic;
