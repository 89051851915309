import Paragraph from "antd/es/typography/Paragraph";
import React from "react";
import { Link } from "react-router-dom";
import { footerLinks } from "../../../Utils/staticsData";

const FooterLinkHolder = ({ id, name, links }) => (
  <div className={id !== 1 ? "footer-link-holder" : "footer-link-holder-1"}>
    <Paragraph className="footer-links-header text-16">{name}</Paragraph>
    {links.map(({ link, label, faq_Config, description, name }) => (
      <Link
        to={link}
        state={{
          label,
          faq: JSON.stringify(faq_Config),
          description,
          name,
        }}
      >
        <Paragraph className="footer-link">{label}</Paragraph>
      </Link>
    ))}
  </div>
);

const Column2 = () => {
  return (
    <div className="footer-links-main-container">
      {footerLinks.map((data) => (
        <FooterLinkHolder {...data} />
      ))}
    </div>
  );
};

export default Column2;
