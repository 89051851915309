import { Col, Row, Skeleton, Typography } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useSelector } from "react-redux";
import ServiceCard from "../../Components/Plans/ServiceCard";
import ServiceDetail from "../../Components/Plans/ServiceDetail";

const Plans = () => {
  const { allServices, loading } = useSelector((state) => state.planSlice);
  return (
    <div className="plan-container">
      <Title level={3} className="plan-step-heading">
        Choose your Services
      </Title>
      <Row gutter={[24, 24]}>
        <Col className="service-column" xs={24} md={8}>
          {loading ? (
            <Skeleton />
          ) : (
            allServices?.map((service) => <ServiceCard {...service} />)
          )}
        </Col>
        <Col className="service-detail-column" xs={24} md={16}>
          {loading ? <Skeleton /> : <ServiceDetail />}
        </Col>
      </Row>
    </div>
  );
};

export default Plans;
