import Paragraph from "antd/es/typography/Paragraph";
import React from "react";
import { Row, Col, Popover, Typography } from "antd";
import { Link, useLocation } from "react-router-dom";
import { pathChecker } from "../Utils/helpers";
import {
  gstAdvisoryServices,
  startupServices,
  vpobServices,
} from "../Utils/staticsData";

const NavItems = ({ label, hasSubContent }) => {
  const navPopperRender = () => {
    switch (label) {
      case "Startup and Compliance":
        return startupServices;
      case "VPOB APOB":
        return vpobServices;
      case "GST Advisory":
        return gstAdvisoryServices;
      default:
        return [];
    }
  };
  console.log("navPopperRender", navPopperRender());
  return (
    <div
      className={
        label === "Startup and Compliance"
          ? "startup-popper-container"
          : "popper-container"
      }
    >
      <Row gutter={[0, 16]}>
        {hasSubContent
          ? navPopperRender().map(({ title, serviceId, links }) => (
              <Col md={8}>
                {title && (
                  <Typography className="popper-nav-header text-primary">
                    {" "}
                    {title}
                  </Typography>
                )}

                {links.map(
                  ({ label, planId, description, faq_Config, name }) => (
                    <div>
                      {" "}
                      <Link
                        className="service-card-link"
                        to="/contact-us"
                        state={{
                          label,
                          faq: JSON.stringify(faq_Config),
                          description,
                          name,
                          serviceId,
                          planId,
                          dontCall: true,
                        }}
                      >
                        {label}
                      </Link>
                    </div>
                  )
                )}
              </Col>
            ))
          : navPopperRender().map(
              ({
                label,
                faq_Config,
                serviceId,
                planId,
                stateId,
                description,
                name,
              }) => (
                <Col md={8}>
                  {console.log("stateId in links", stateId, label)}
                  <Link
                    className="service-card-link"
                    to="/contact-us"
                    state={{
                      label,
                      faq: JSON.stringify(faq_Config),
                      description,
                      name,
                      serviceId,
                      planId,
                      stateId,
                    }}
                  >
                    {label}
                  </Link>
                </Col>
              )
            )}
      </Row>
    </div>
  );
};
const NavLink = ({ link, label, isMobile, hasSubContent, noContent }) => {
  const { pathname } = useLocation();
  return (
    <>
      <Popover
        content={
          !noContent && <NavItems label={label} hasSubContent={hasSubContent} />
        }
        // open={label === "VPOB APOB"}
      >
        <Link to={link} style={isMobile && { marginBottom: "50px" }}>
          <Paragraph
            className={
              pathChecker(pathname, link)
                ? "text-primary nav-link typography-reset-margin"
                : "nav-link typography-reset-margin text-primay"
            }
            style={{ color: "#2F2F30" }}
          >
            {" "}
            {label}
          </Paragraph>
        </Link>
      </Popover>
    </>
  );
};

export default NavLink;
