import React from "react";
import "./index.scss";
import Title from "antd/es/typography/Title";
import { startupServices } from "../../../Utils/staticsData";
import { Col, Row } from "antd";
import StartupServiceCard from "./StartupServiceCard";

const StartupService = ({ services }) => {
  return (
    <div className="startup-service-conatiner">
      <Title level={2} className="gst-services-heading ">
        Startup Services
      </Title>
      <Row gutter={[12, 12]} className="startup-service-row">
        {startupServices.map((service) => (
          <Col md={8}>
            <StartupServiceCard {...service}></StartupServiceCard>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default StartupService;
