import { Divider, Radio, Typography } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedPlan,
  setSelectedPlanNew,
  setSelectedState,
  setSelectedStateNew,
  setSummaryPlanPrice,
} from "../../Redux/Slices/PlanSlice";
import {
  currencyFormatter,
  planPriceBasedUponState,
} from "../../Utils/helpers";
import circle from "../../Assets/Icons/circle-information.svg";

const ServiceDetail = () => {
  const {
    selectedPlanNew,
    selectedStateNew,
    selectedServiceNew,
    selectedService,
    selectedPlan,
    selectedState,
    summary,
  } = useSelector((state) => state.planSlice);
  const dispatch = useDispatch();
  const selectPlan = ({ plan, price }) => {
    dispatch(setSelectedPlanNew(plan));

    dispatch(setSummaryPlanPrice(price));
    // planPriceBasedUponState(
    //   selectedState,
    //   selectedService,
    //   plan.title,

    // );
  };
  const selectState = (state) => {
    dispatch(setSelectedStateNew(state));
    dispatch(setSummaryPlanPrice(state.price));
  };
  const cardPriceRender = (price) => {
    if (typeof price === "string") return price;
    else return currencyFormatter(price);
  };
  return (
    <div className="service-detail-container">
      <div className="plan-state-main-container">
        {selectedPlanNew.statePricing && (
          <div className="plan-state-main-header">
            {" "}
            <Typography className="choose-state-heading">
              Select your State
            </Typography>
            <Typography className="text-12  blue-text price-info">
              <img src={circle} alt="circle" width="16px" />
              Price varies according to states
            </Typography>
          </div>
        )}

        <div className="state-chips-container">
          {selectedPlanNew.statePricing && (
            <>
              {selectedPlanNew?.states?.map((state) => (
                <div
                  className={`plan-state-container cursor ${
                    selectedStateNew?.state?.name === state?.state?.name &&
                    "selected-plan-state"
                  }`}
                  onClick={() => selectState(state)}
                >
                  <img src={state?.state?.iconUrl} alt="state-ico" />
                  <Typography className="plan-state-label">
                    {state?.state?.name}
                  </Typography>
                </div>
              ))}

              <Divider />
            </>
          )}
        </div>
      </div>
      <div className="choose-plan-container">
        <Typography className="choose-plan-heading">
          Choose your Plans
        </Typography>

        {selectedServiceNew?.plans?.map((plan) => (
          <div
            className={`individual-plan-container cursor ${
              selectedPlanNew.id === plan.id && "selected-plan"
            }`}
            id={selectedPlanNew.id === plan.id && "selected-plan"}
            onClick={() =>
              selectPlan({
                plan,
                price: planPriceBasedUponState(
                  selectedStateNew,
                  selectedServiceNew,
                  plan.name,
                  plan.statePricing,
                  plan.price,
                  plan.taxPercentage,
                  "isNum"
                ),
              })
            }
          >
            <div className="plan-box-header">
              <Title level={5} className="typography-reset-margin">
                {" "}
                {plan.name}
              </Title>
              <Radio checked={selectedPlanNew.id === plan.id} />
            </div>

            <Divider />
            <Typography>
              <span className="text-primary bold text-18">
                ₹
                {cardPriceRender(
                  planPriceBasedUponState(
                    selectedStateNew,
                    selectedServiceNew,
                    plan.name,
                    plan.statePricing,
                    plan.price
                  )
                )}
              </span>{" "}
              <span className="font-grey-2 "> {plan.type}</span>{" "}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceDetail;
