import React from "react";
import "./index.scss";
import Title from "antd/es/typography/Title";
import ServiceCard from "./ServiceCard";
import { Col, Row } from "antd";
import { gstAdvisoryServices } from "../../../Utils/staticsData";

const GSTService = () => {
  return (
    <div className="gst-service-conatiner">
      <Title level={2} className="gst-services-heading ">
        GST Advisory Services
      </Title>
      <Row gutter={[12, 12]} className="vpob-service-row">
        {gstAdvisoryServices.map((service) => (
          <Col xs={24} md={8}>
            <ServiceCard {...service}></ServiceCard>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default GSTService;
