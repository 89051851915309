import React, { useEffect, useState } from "react";
import "./index.scss";
import Title from "antd/es/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogs } from "../../Redux/Slices/BlogSlice";
import { Col, Pagination, Row, Skeleton } from "antd";
import BlogCard from "../../Components/BlogCard";

const AllBlogs = () => {
  const { blogs, total, loading } = useSelector((state) => state.blogSlice);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const onChange = (page) => {
    setCurrent(page);
  };
  useEffect(() => {
    dispatch(getAllBlogs({ page: current, limit: 9 }));
  }, [current]);
  return (
    <div className="all-blogs-container">
      <Title level={2} className="all-blogs-heading  text-black ">
        Our <span className="text-primary bold">Blogs</span>
      </Title>
      <Row gutter={[24, 24]}>
        {loading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          blogs.map((blog) => (
            <Col xs={24} md={8}>
              <BlogCard {...blog} singleBlog />
            </Col>
          ))
        )}
      </Row>
      <div className="all-blogs-pagination-container">
        <Pagination onChange={onChange} total={total} />
      </div>
    </div>
  );
};

export default AllBlogs;
