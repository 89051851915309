import { Button, Card } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
// import { RightOutlined } from "@ant-design/icons";
import rtArrow from "../../../../Assets/Icons/chevron-right.svg";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";

const GSTCard = ({ image, label, faq_Config, description, name }) => {
  return (
    <Card className="gst-card">
      <div className="gst-semi-circle">
        <img src={image} alt="gst-i" className="gst-i" />
      </div>
      <div className="gst-card-right-container">
        <Title level={2} className=" typography-reset-margin">
          {label}
        </Title>
        <Link
          className="service-card-link"
          to="/contact-us"
          state={{
            label,
            faq: JSON.stringify(faq_Config),
            description,
            name,
          }}
        >
          <Button
            icon={<ReactSVG src={rtArrow} className="gst-rt-arrow" />}
            className="gst-arrow-btn"
          />
        </Link>
      </div>
    </Card>
  );
};

export default GSTCard;
