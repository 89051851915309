import { Typography } from "antd";
import React from "react";

const ProcessCard = ({ label, text, index }) => {
  return (
    <div className={`process-card process-bg-${index + 1} `}>
      <Typography className="process-title">{label}</Typography>
      <Typography className="process-text text-14 font-grey-2">
        {text}
      </Typography>
    </div>
  );
};

export default ProcessCard;
