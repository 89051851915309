import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { foursteps, landingServiceData, threeSteps } from './staticsData';
import girl1 from '../Assets/Images/girl_1.png';
import girl2 from '../Assets/Images/girl_2.png';
import girl3 from '../Assets/Images/girl_3.png';
import girl4 from '../Assets/Images/girl_4.png';
import banner1 from '../Assets/Images/banner-1.jpg';
import banner2 from '../Assets/Images/banner-2.jpg';
import banner3 from '../Assets/Images/banner-3.jpg';
import banner4 from '../Assets/Images/banner-4.jpg';
import { fancyUlTextRenderer } from './renderer';
import fancyLine from '../Assets/Icons/styledLine.svg';
import { setSummaryPlanPrice } from '../Redux/Slices/PlanSlice';

export const pathChecker = (location, pathname) => {
  if (location === pathname) return true;
  else return false;
};

export const bannerImageChecker = (pathname) => {
  switch (pathname) {
    case '/startup-compliance':
      return banner1;
    case '/vpob-apob':
      return banner4;
    case '/gst-advisory':
      return banner3;
    case '/fixed-desk':
      return banner2;
    default:
      return 'internal-banner-1.jpg';
  }
};

export const privacyRenderer = (pathname) => {
  console.log('pathname', pathname);
  switch (pathname) {
    case '/privacy-policy':
      return (
        <>
          <h1>Easy Works Privacy Policy</h1>
          <p>
            At Easy Works, we understand that privacy is important to you, and
            it is equally important to us. We are committed to protecting your
            personal information and ensuring a safe and secure experience while
            using our services. This Privacy Policy outlines how we collect,
            use, and protect your personal data in compliance with Indian
            privacy laws and the Department of Commerce, Government of India.
          </p>

          <section>
            <h2>Collection of Information</h2>
            <p>
              We collect both Personal Information and Non-Personal Information
              to provide you with a customized and efficient service. This helps
              us enhance your experience and improve the functionality of our
              website.
            </p>

            <h3>Personal Information</h3>
            <p>
              Personal information is any data that can be used to identify you
              as an individual. This includes details such as:
            </p>
            <ul>
              <li>Your name, address, email address, phone number</li>
              <li>Payment information (e.g., credit card number)</li>
              <li>Other related information you provide when using our site</li>
            </ul>

            <h3>Non-Personal Information</h3>
            <p>
              Non-personal information is data that cannot be used to identify
              you directly. Examples include:
            </p>
            <ul>
              <li>Your IP address, browser type, device information</li>
              <li>Pages viewed on our website</li>
            </ul>
            <p>
              We may also use cookies and similar technologies to track user
              behavior and improve your browsing experience. This data is
              aggregated and does not identify you personally.
            </p>
          </section>

          <section>
            <h2>How We Use Your Information</h2>
            <h3>1. Personal Information</h3>
            <p>
              We use your personal data to process your requests, respond to
              inquiries, and provide you with the services you require. We will
              not use your personal information without your consent, except as
              described in this policy.
            </p>

            <h3>2. Non-Personal Information</h3>
            <p>
              We aggregate non-personal data to understand trends, monitor
              website usage, and enhance user experience. This helps us improve
              the design and functionality of our site.
            </p>
          </section>

          <section>
            <h2>Cookie Policy</h2>
            <p>
              By using or accessing our website, you consent to our use of
              cookies. Cookies are small files that store information on your
              device to help improve your browsing experience. Here’s how we use
              them:
            </p>
            <ul>
              <li>
                <strong>Analytical Cookies:</strong> Help us understand user
                activity and make improvements to the site.
              </li>
              <li>
                <strong>Preference Cookies:</strong> Remember your preferences
                and settings, such as recent searches.
              </li>
              <li>
                <strong>Marketing Cookies:</strong> Display targeted
                advertisements on other websites you visit.
              </li>
              <li>
                <strong>Session Cookies:</strong> Manage your session while
                using our site.
              </li>
            </ul>
            <p>
              You can control cookie usage through your browser settings.
              Disabling cookies may affect some site features.
            </p>
          </section>

          <section>
            <h2>Interest-Based Advertising and Google Analytics</h2>
            <p>
              We use tools like Google Analytics and Google AdWords to collect
              data about user behavior and display personalized ads. This data
              may include your IP address, browser information, and search
              activity.
            </p>
            <p>
              You can opt out of personalized advertising by visiting the {''}
              <a href="https://adssettings.google.com/" target="_blank">
                Google Ads Preferences Manager
              </a>{' '}
              or using the{' '}
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
              >
                Google Analytics Opt-out Browser Add-on
              </a>
              .
            </p>
          </section>

          <section>
            <h2>Communication from Easy Works</h2>
            <ul>
              <li>A welcome email to verify your account.</li>
              <li>
                Information regarding your account or any services you have
                requested.
              </li>
              <li>
                Promotional offers, newsletters, and updates on new services or
                special promotions.
              </li>
            </ul>
            <p>
              You can opt out of these communications at any time by following
              the instructions in the email or by contacting us directly.
            </p>
          </section>

          <section>
            <h2>Opting Out</h2>
            <p>
              If you no longer wish to receive marketing emails or promotional
              materials, you can unsubscribe by clicking the "unsubscribe" link
              in our emails or by emailing us at{' '}
              <a href="mailto:bookings@easyworks.in">bookings@easyworks.in</a>{' '}
              with "Opt-Out" in the subject line.
            </p>
          </section>

          <section>
            <h2>Access to Your Information</h2>
            <p>
              You have the right to access and update your personal information.
              To make changes or request a copy of your data, please contact us
              at{' '}
              <a href="mailto:bookings@easyworks.in">bookings@easyworks.in</a>.
              We will take reasonable steps to verify your identity before
              granting access.
            </p>
          </section>

          <section>
            <h2>Sharing with Third Parties</h2>
            <p>
              We do not sell or rent your personal information. However, we may
              share your data with trusted third parties in the following
              situations:
            </p>
            <ul>
              <li>
                To service providers who assist us in operating our website or
                providing services to you.
              </li>
              <li>
                To comply with legal obligations or protect rights, property, or
                safety.
              </li>
              <li>
                In the event of a merger, acquisition, or sale of our business
                assets.
              </li>
            </ul>
          </section>

          <section>
            <h2>Security</h2>
            <p>
              We implement a range of technical and organizational measures to
              safeguard your personal information. We regularly review and
              update these measures to ensure ongoing protection.
            </p>
          </section>

          <section>
            <h2>Changes to this Privacy Policy</h2>
            <p>
              We may update this Privacy Policy from time to time. Any changes
              will be posted on our website. We encourage you to review this
              policy periodically.
            </p>
          </section>

          <section>
            <h2>Contact Us</h2>
            <p class="contact">
              If you have any questions or concerns, please contact us at:{' '}
              <a href="mailto:bookings@easyworks.in">bookings@easyworks.in</a>
            </p>
            <p>
              We value your privacy and are committed to protecting your
              personal information.
            </p>
          </section>
        </>
      );
    case '/refund-policy':
      return (
        <body>
          <h1>EASY WORKS Refund Policy</h1>

          <p>
            At EASY WORKS, we prioritize your satisfaction and success. We
            deeply value the trust you place in us and are committed to
            providing exceptional service and support. Our Refund Policy is
            designed to be transparent and fair, ensuring your needs and
            concerns are always addressed.
          </p>

          <p>
            We understand that dealing with GST applications and related
            services can sometimes be complex. That’s why we are here to guide
            you every step of the way. Should any issues arise, we are dedicated
            to resolving them promptly and effectively.
          </p>

          <h2>Eligibility for Refund</h2>

          <h3>1. VPOB Applications (Amazon & Flipkart):</h3>
          <ul>
            <li>
              <strong>Rejection Criteria:</strong> If your GST application is
              rejected three times or more, you are eligible for a refund,
              subject to a deduction of Rs 2,500. Refunds will not be processed
              for any reason other than the application being rejected three
              times.
            </li>
          </ul>

          <h3>
            2. Registrations, Virtual Office, GST Registration Service, APOB, or
            Other Additional Services:
          </h3>
          <ul>
            <li>
              <strong>24-Hour Request Window:</strong> Refund requests must be
              submitted within 24 hours of payment. Requests should be sent to
              bookings@easyworks.in within this time frame. Any requests made
              after 24 hours will not be eligible for a refund, as services will
              have already been initiated.
            </li>
            <li>
              <strong>Service Credits:</strong> If there are delays caused by
              our operations team, we will offer service credits as
              compensation.
            </li>
            <li>
              <strong>Rejection Criteria:</strong> If your GST application is
              rejected three times or more, you are eligible for a refund,
              subject to a deduction of Rs 3,000. Refunds will not be processed
              for any reason other than the application being rejected three
              times.
            </li>
            <li>
              <strong>
                In cases where the Easy Works team approves the KYC but is
                rejected by the space partner for any reason:
              </strong>{' '}
              In such cases, the entire amount will be refunded after deducting
              INR 2,000+GST as admin fees.
            </li>
            <li>
              <strong>
                In cases where the client is unable to provide the required KYC
                documents and details within 90 days of payment:
              </strong>{' '}
              The solution will be forfeited.
            </li>
            <li>
              <strong>
                In cases where dummy directors are being used to defraud the
                system by creating bogus companies/shell companies:
              </strong>{' '}
              The entire amount paid will be fully forfeited, and the client
              will be blacklisted from our system.
            </li>
          </ul>

          <h3>3. Time Period:</h3>
          <p>Any Refund Accepted shall be processed within 15 days.</p>

          <h2>Factors Beyond Our Control</h2>

          <h3>1. Outcome Guarantee:</h3>
          <p>
            While we strive to deliver successful outcomes, we cannot guarantee
            the result of your application. For example, the government may
            reject a GST application for legal or regulatory reasons that are
            beyond our control.
          </p>

          <h3>2. Government Delays:</h3>
          <p>
            In the event of delays caused by government agencies (e.g., GST
            Portal downtime, backlog issues, or other platform-related
            disruptions), EASY WORKS will not be liable for refunds.
          </p>

          <h2>How to Request a Refund</h2>

          <p>
            To request a refund, please send an email to bookings@easyworks.in
            with the subject line: "Refund Request - [Your Name]". Be sure to
            include all relevant details and documentation to help expedite the
            process.
          </p>

          <h2>Policy Amendments</h2>

          <p>
            EASY WORKS reserves the right to amend or modify this Refund Policy
            at any time. Any changes will be effective immediately upon posting
            on our website or upon written communication to you.
          </p>

          <p>
            We are committed to maintaining transparency and fairness, ensuring
            the best possible service for all our clients. By adhering to this
            policy, we aim to provide clarity, accountability, and a smooth
            experience for every customer.
          </p>
        </body>
      );
    case '/terms-of-service':
      return (
        <>
          {' '}
          <h1>Terms of Service</h1>
          <h2>OVERVIEW</h2>
          <p>
            This website is operated by EASY WORKS. Throughout the site, the
            terms “we”, “us” and “our” refer to EASY WORKS. EASY WORKS offers
            this website, including all information, tools and Services
            available from this site to you, the user, conditioned upon your
            acceptance of all terms, conditions, policies and notices stated
            here.
          </p>
          <p>
            By visiting our site and/or purchasing something from us, you engage
            in our “Service” and agree to be bound by the following terms and
            conditions (“Terms of Service”, “Terms”), including those additional
            terms and conditions and policies referenced herein and/or available
            by hyperlink. These Terms of Service apply to all users of the site,
            including without limitation users who are browsers, vendors,
            customers, merchants, and/or contributors of content.
          </p>
          <p>
            Please read these Terms of Service carefully before accessing or
            using our website. By accessing or using any part of the site, you
            agree to be bound by these Terms of Service. If you do not agree to
            all the terms and conditions of this agreement, then you may not
            access the website or use any Services. If these Terms of Service
            are considered an offer, acceptance is expressly limited to these
            Terms of Service.
          </p>
          <p>
            Any new features or tools which are added to the current store shall
            also be subject to the Terms of Service. You can review the most
            current version of the Terms of Service at any time on this page. We
            reserve the right to update, change or replace any part of these
            Terms of Service by posting updates and/or changes to our website.
            It is your responsibility to check this page periodically for
            changes. Your continued use of or access to the website following
            the posting of any changes constitutes acceptance of those changes.
          </p>
          <p>
            Our store is hosted on Shopify Inc. They provide us with the online
            e-commerce platform that allows us to sell our products and Services
            to you.
          </p>
          <h2>SECTION 1 - ONLINE STORE TERMS</h2>
          <p>
            By agreeing to these Terms of Service, you represent that you are at
            least the age of majority in your state or province of residence, or
            that you are the age of majority in your state or province of
            residence and you have given us your consent to allow any of your
            minor dependents to use this site.
          </p>
          <p>
            You may not use our products for any illegal or unauthorized purpose
            nor may you, in the use of the Service, violate any laws in your
            jurisdiction (including but not limited to copyright laws).
          </p>
          <p>
            You must not transmit any worms or viruses or any code of a
            destructive nature.
          </p>
          <p>
            A breach or violation of any of the Terms will result in an
            immediate termination of your Services.
          </p>
          <h2>SECTION 2 - GENERAL CONDITIONS</h2>
          <p>
            You understand that your content (not including credit card
            information), may be transferred unencrypted and involve (a)
            transmissions over various networks; and (b) changes to conform and
            adapt to technical requirements of connecting networks or devices.
            Credit card information is always encrypted during transfer over
            networks.
          </p>
          <p>
            You agree not to reproduce, duplicate, copy, sell, resell or exploit
            any portion of the Service, use of the Service, or access to the
            Service or any contact on the website through which the Service is
            provided, without express written permission by us.
          </p>
          <p>
            The headings used in this agreement are included for convenience
            only and will not limit or otherwise affect these Terms.
          </p>
          <h2>
            SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
          </h2>
          <p>
            We are not responsible if information made available on this site is
            not accurate, complete or current. The material on this site is
            provided for general information only and should not be relied upon
            or used as the sole basis for making decisions without consulting
            primary, more accurate, more complete or more timely sources of
            information. Any reliance on the material on this site is at your
            own risk.
          </p>
          <p>
            This site may contain certain historical information. Historical
            information, necessarily, is not current and is provided for your
            reference only. We reserve the right to modify the contents of this
            site at any time, but we have no obligation to update any
            information on our site. You agree that it is your responsibility to
            monitor changes to our site.
          </p>{' '}
          <h2>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</h2>
          <p>Prices for our products are subject to change without notice.</p>
          <p>
            We reserve the right at any time to modify or discontinue the
            Service (or any part or content thereof) without notice at any time.
          </p>
          <p>
            We shall not be liable to you or to any third-party for any
            modification, price change, suspension or discontinuance of the
            Service.
          </p>
          <h2>SECTION 5 - PRODUCTS OR SERVICES (if applicable)</h2>
          <p>
            Certain products or Services may be available exclusively online
            through the website. These products or Services may have limited
            quantities and are subject to return or exchange only according to
            our Refund Policy.
            {/* <a href="[LINK TO REFUND POLICY]">[LINK TO REFUND POLICY]</a> */}
          </p>
          <p>
            We have made every effort to display as accurately as possible the
            colors and images of our products that appear at the store. We
            cannot guarantee that your computer monitor's display of any color
            will be accurate.
          </p>
          <p>
            We reserve the right, but are not obligated, to limit the sales of
            our products or Services to any person, geographic region or
            jurisdiction. We may exercise this right on a case-by-case basis. We
            reserve the right to limit the quantities of any products or
            Services that we offer. All descriptions of products or product
            pricing are subject to change at anytime without notice, at the sole
            discretion of us. We reserve the right to discontinue any product at
            any time. Any offer for any product or Service made on this site is
            void where prohibited.
          </p>
          <p>
            We do not warrant that the quality of any products, Services,
            information, or other material purchased or obtained by you will
            meet your expectations, or that any errors in the Service will be
            corrected.
          </p>
          <h2>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</h2>
          <p>
            We reserve the right to refuse any order you place with us. We may,
            in our sole discretion, limit or cancel quantities purchased per
            person, per household or per order. These restrictions may include
            orders placed by or under the same customer account, the same credit
            card, and/or orders that use the same billing and/or shipping
            address. In the event that we make a change to or cancel an order,
            we may attempt to notify you by contacting the e‑mail and/or billing
            address/phone number provided at the time the order was made. We
            reserve the right to limit or prohibit orders that, in our sole
            judgment, appear to be placed by dealers, resellers or distributors.
          </p>
          <p>
            You agree to provide current, complete and accurate purchase and
            account information for all purchases made at our store. You agree
            to promptly update your account and other information, including
            your email address and credit card numbers and expiration dates, so
            that we can complete your transactions and contact you as needed.
          </p>
          <p>
            For more details, please review our Refund Policy:{' '}
            <a href="[LINK TO REFUND POLICY]">[LINK TO REFUND POLICY]</a>
          </p>
          <h2>SECTION 7 - OPTIONAL TOOLS</h2>
          <p>
            We may provide you with access to third-party tools over which we
            neither monitor nor have any control nor input.
          </p>
          <p>
            You acknowledge and agree that we provide access to such tools “as
            is” and “as available” without any warranties, representations or
            conditions of any kind and without any endorsement. We shall have no
            liability whatsoever arising from or relating to your use of
            optional third-party tools.
          </p>
          <p>
            Any use by you of the optional tools offered through the site is
            entirely at your own risk and discretion and you should ensure that
            you are familiar with and approve of the terms on which tools are
            provided by the relevant third-party provider(s).
          </p>
          <p>
            We may also, in the future, offer new Services and/or features
            through the website (including the release of new tools and
            resources). Such new features and/or Services shall also be subject
            to these Terms of Service.
          </p>
          <h2>SECTION 8 - THIRD-PARTY LINKS</h2>
          <p>
            Certain content, products and Services available via our Service may
            include materials from third-parties.
          </p>
          <p>
            Third-party links on this site may direct you to third-party
            websites that are not affiliated with us. We are not responsible for
            examining or evaluating the content or accuracy and we do not
            warrant and will not have any liability or responsibility for any
            third-party materials or websites, or for any other materials,
            products, or Services of third-parties.
          </p>
          <p>
            We are not liable for any harm or damages related to the purchase or
            use of goods, Services, resources, content, or any other
            transactions made in connection with any third-party websites.
            Please review carefully the third-party's policies and practices and
            make sure you understand them before you engage in any transaction.
            Complaints, claims, concerns, or questions regarding third-party
            products should be directed to the third-party.
          </p>
          <h2>SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h2>
          <p>
            If, at our request, you send certain specific submissions (for
            example contest entries) or without a request from us, you send
            creative ideas, suggestions, proposals, plans, or other materials,
            whether online, by email, by postal mail, or otherwise
            (collectively, 'comments'), you agree that we may, at any time,
            without restriction, edit, copy, publish, distribute, translate and
            otherwise use in any medium any comments that you forward to us. We
            are and shall be under no obligation (1) to maintain any comments in
            confidence; (2) to pay compensation for any comments; or (3) to
            respond to any comments.
          </p>
          <p>
            We may, but have no obligation to, monitor, edit or remove content
            that we determine in our sole discretion to be unlawful, offensive,
            threatening, libelous, defamatory, pornographic, obscene or
            otherwise objectionable or violates any party’s intellectual
            property or these Terms of Service.
          </p>
          <p>
            You agree that your comments will not violate any right of any
            third-party, including copyright, trademark, privacy, personality or
            other personal or proprietary right. You further agree that your
            comments will not contain libelous or otherwise unlawful, abusive or
            obscene material, or contain any computer virus or other malware
            that could in any way affect the operation of the Service or any
            related website. You may not use a false e‑mail address, pretend to
            be someone other than yourself, or otherwise mislead us or
            third-parties as to the origin of any comments. You are solely
            responsible for any comments you make and their accuracy. We take no
            responsibility and assume no liability for any comments posted by
            you or any third-party.
          </p>
          <h2>SECTION 10 - PERSONAL INFORMATION</h2>
          <p>
            Your submission of personal information through the store is
            governed by our Privacy Policy, which can be viewed here:
            <a href="[LINK TO PRIVACY POLICY]">[LINK TO PRIVACY POLICY]</a>
          </p>
          <h2>SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</h2>
          <p>
            Occasionally there may be information on our site or in the Service
            that contains typographical errors, inaccuracies or omissions that
            may relate to product descriptions, pricing, promotions, offers,
            product shipping charges, transit times and availability. We reserve
            the right to correct any errors, inaccuracies or omissions, and to
            change or update information or cancel orders if any information in
            the Service or on any related website is inaccurate at any time
            without prior notice (including after you have submitted your
            order).
          </p>
          <p>
            We undertake no obligation to update, amend or clarify information
            in the Service or on any related website, including without
            limitation, pricing information, except as required by law. No
            specified update or refresh date applied in the Service or on any
            related website, should be taken to indicate that all information in
            the Service or on any related website has been modified or updated.
          </p>
          <h2>SECTION 12 - PROHIBITED USES</h2>
          <p>
            In addition to other prohibitions as set forth in the Terms of
            Service, you are prohibited from using the site or its content: (a)
            for any unlawful purpose; (b) to solicit others to perform or
            participate in any unlawful acts; (c) to violate any international,
            federal, provincial or state regulations, rules, laws, or local
            ordinances; (d) to infringe upon or violate our intellectual
            property rights or the intellectual property rights of others; (e)
            to harass, abuse, insult, harm, defame, slander, disparage,
            intimidate, or discriminate based on gender, sexual orientation,
            religion, ethnicity, race, age, national origin, or disability; (f)
            to submit false or misleading information; (g) to upload or transmit
            viruses or any other type of malicious code that will or may be used
            in any way that will affect the functionality or operation of the
            Service or of any related website, other websites, or the Internet;
            (h) to collect or track the personal information of others; (i) to
            spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any
            obscene or immoral purpose; or (k) to interfere with or circumvent
            the security features of the Service or any related website, other
            websites, or the Internet. We reserve the right to terminate your
            use of the Service or any related website for violating any of the
            prohibited uses.
          </p>
          <h2>
            SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
          </h2>
          <p>
            We do not guarantee, represent or warrant that your use of our
            Service will be uninterrupted, timely, secure or error-free.
          </p>
          <p>
            We do not warrant that the results that may be obtained from the use
            of the Service will be accurate or reliable.
          </p>
          <p>
            You agree that from time to time we may remove the Service for
            indefinite periods of time or cancel the Service at any time,
            without notice to you.
          </p>
          <p>
            You expressly agree that your use of, or inability to use, the
            Service is at your sole risk. The Service and all products and
            Services delivered to you through the Service are (except as
            expressly stated by us) provided 'as is' and 'as available' for your
            use, without any representation, warranties or conditions of any
            kind, either express or implied, including all implied warranties or
            conditions of merchantability, merchantable quality, fitness for a
            particular purpose, durability, title, and non-infringement.
          </p>
          <p>
            In no case shall EASY WORKS, our directors, officers, employees,
            affiliates, agents, contractors, interns, suppliers, Service
            providers or licensors be liable for any injury, loss, claim, or any
            direct, indirect, incidental, punitive, special, or consequential
            damages of any kind, including, without limitation lost profits,
            lost revenue, lost savings, loss of data, replacement costs, or any
            similar damages, whether based in contract, tort (including
            negligence), strict liability or otherwise, arising from your use of
            any of the Service or any products procured using the Service, or
            for any other claim related in any way to your use of the Service or
            any product, including, but not limited to, any errors or omissions
            in any content, or any loss or damage of any kind incurred as a
            result of the use of the Service or any content (or product) posted,
            transmitted, or otherwise made available via the Service, even if
            advised of their possibility. Because some states or jurisdictions
            do not allow the exclusion or the limitation of liability for
            consequential or incidental damages, in such states or
            jurisdictions, our liability shall be limited to the maximum extent
            permitted by law.
          </p>{' '}
          <h2>SECTION 14 - INDEMNIFICATION</h2>
          <p>
            You agree to indemnify, defend and hold harmless EASY WORKS and our
            parent, subsidiaries, affiliates, partners, officers, directors,
            agents, contractors, licensors, Service providers, subcontractors,
            suppliers, interns, and employees, harmless from any claim or
            demand, including reasonable attorneys’ fees, made by any
            third-party due to or arising out of your breach of these Terms of
            Service or the documents they incorporate by reference, or your
            violation of any law or the rights of a third-party.
          </p>
          <h2>SECTION 15 - SEVERABILITY</h2>
          <p>
            In the event that any provision of these Terms of Service is
            determined to be unlawful, void, or unenforceable, such provision
            shall nonetheless be enforceable to the fullest extent permitted by
            applicable law, and the unenforceable portion shall be deemed to be
            severed from these Terms of Service. Such determination shall not
            affect the validity and enforceability of any other remaining
            provisions.
          </p>
          <h2>SECTION 16 - TERMINATION</h2>
          <p>
            The obligations and liabilities of the parties incurred prior to the
            termination date shall survive the termination of this agreement for
            all purposes.
          </p>
          <p>
            These Terms of Service are effective unless and until terminated by
            either you or us. You may terminate these Terms of Service at any
            time by notifying us that you no longer wish to use our Services, or
            when you cease using our site.
          </p>
          <p>
            If in our sole judgment you fail, or we suspect that you have
            failed, to comply with any term or provision of these Terms of
            Service, we also may terminate this agreement at any time without
            notice and you will remain liable for all amounts due up to and
            including the date of termination; and/or accordingly may deny you
            access to our Services (or any part thereof).
          </p>
          <h2>SECTION 17 - ENTIRE AGREEMENT</h2>
          <p>
            The failure of us to exercise or enforce any right or provision of
            these Terms of Service shall not constitute a waiver of such right
            or provision.
          </p>
          <p>
            These Terms of Service and any policies or operating rules posted by
            us on this site or in respect to the Service constitute the entire
            agreement and understanding between you and us and governs your use
            of the Service, superseding any prior or contemporaneous agreements,
            communications, and proposals, whether oral or written, between you
            and us (including, but not limited to, any prior versions of the
            Terms of Service).
          </p>
          <p>
            Any ambiguities in the interpretation of these Terms of Service
            shall not be construed against the drafting party.
          </p>
          <h2>SECTION 18 - GOVERNING LAW</h2>
          <p>
            These Terms of Service and any separate agreements whereby we
            provide you Services shall be governed by and construed in
            accordance with the laws of India.
          </p>{' '}
          <h2>SECTION 19 - CHANGES TO TERMS OF SERVICE</h2>
          <p>
            You can review the most current version of the Terms of Service at
            any time at this page.
          </p>
          <p>
            We reserve the right, at our sole discretion, to update, change or
            replace any part of these Terms of Service by posting updates and
            changes to our website. It is your responsibility to check our
            website periodically for changes. Your continued use of or access to
            our website or the Service following the posting of any changes to
            these Terms of Service constitutes acceptance of those changes.
          </p>
          <h2>SECTION 20 - CONTACT INFORMATION</h2>
          <p>
            Questions about the Terms of Service should be sent to us at{' '}
            <a href="mailto:bookings@easyworks.in">bookings@easyworks.in</a>
          </p>
          <p>Our contact information is posted below:</p>
          <p>
            EASY WORKS
            <br />
            <a href="mailto:bookings@easyworks.in">bookings@easyworks.in</a>
            <br />
            3198, Sector 23, Gurugram, Haryana
          </p>
        </>
      );
    default:
      return '';
  }
};
export const bannerChecker = (pathname) => {
  switch (pathname) {
    case '/startup-compliance':
      return (
        <>
          <Title level={4} className="compliance-banner-upper-text">
            Startup and Compliance
          </Title>
          <Title
            level={1}
            className="compliance-banner-middle-text  typography-reset-margin"
          >
            Your partner for startup compliance and{' '}
            {fancyUlTextRenderer(
              fancyLine,
              'bold',
              'regulatory success.',
              '#2F2F30'
            )}
          </Title>
          <Paragraph className="compliance-banner-description text-center banner-description font-300 text-16 text-black  typography-reset-margin">
            Our comprehensive suite of services covers every aspect of business
            setup and regulatory compliance, ensuring you're set up for success
            from day one. Whether you're in need of Accounting Solutions, GST
            Compliance, Company Registration, E-commerce Payment Reconciliation,
            FSSAI or any other essential compliances, we've got you covered.
          </Paragraph>
        </>
      );
    case '/vpob-apob':
      return (
        <>
          <Title level={4} className="compliance-banner-upper-text ">
            VPOB & APOB
          </Title>
          <Title
            level={1}
            className="compliance-banner-middle-text  typography-reset-margin"
          >
            Your GST -ready virtual{' '}
            {fancyUlTextRenderer(
              fancyLine,
              'bold',
              'office spaces.',
              '#2F2F30'
            )}
          </Title>
          <Paragraph className="compliance-banner-description text-center banner-description font-300 text-16 text-black  typography-reset-margin">
            we are highly focused on serving ecommerce sellers with taxation,
            compliance and legal services. Get VPOB and Scale your Ecommerce
            Revenue and Reach Now
          </Paragraph>
        </>
      );
    case '/gst-advisory':
      return (
        <>
          <Title level={4} className="compliance-banner-upper-text">
            GST Advisory
          </Title>
          <Title
            level={1}
            className="compliance-banner-middle-text  typography-reset-margin"
          >
            Effortless{' '}
            {fancyUlTextRenderer(fancyLine, 'bold', 'GST solutions', '#2F2F30')}{' '}
            for every business.
          </Title>
          <Paragraph className="compliance-banner-description text-center banner-description font-300 text-16 text-black  typography-reset-margin">
            we are highly focused on serving businesses in terms of GST Review,
            GST Litigation, Obtaining Refunds, GST Compliance and Representation
            before the GST Department.
          </Paragraph>
        </>
      );
    case '/fixed-desk':
      return (
        <>
          <Title level={4} className="compliance-banner-upper-text ">
            Fixed Desk
          </Title>
          <Title
            level={1}
            className="compliance-banner-middle-text  typography-reset-margin"
          >
            Complete compliance
            <br />
            {fancyUlTextRenderer(
              fancyLine,
              'bold',
              'Total convenience.',
              '#2F2F30'
            )}
          </Title>
        </>
      );
    default:
      break;
  }
};

export const toggleTextVisibility = (
  index,
  setData,
  setShowServiceCard,
  showServiceCard,
  manual
) => {
  const indexUpdated = index === 4 ? 1 : index + 1;
  // const indexManual = index === 4 ? 1 : index;
  switch (manual ? index : indexUpdated) {
    case 1:
      setData(landingServiceData.startup);
      break;
    case 2:
      setData(landingServiceData.apob);
      break;
    case 3:
      setData(landingServiceData.gst);
      break;
    case 4:
      setData(landingServiceData.fixedDesk);
      break;
    default:
      setData(landingServiceData.startup);
  }
  if (showServiceCard !== index) {
    setShowServiceCard(index);
  }
};

export const renderGirl = (index) => {
  switch (index) {
    case 1:
      return girl1;
    case 2:
      return girl2;
    case 3:
      return girl3;
    case 4:
      return girl4;
    default:
      return girl1;
  }
};

export const serviceFloaterDynamicPosition = (position, isMobile) => {
  const positionToUse = {
    xs: {
      right: position.xs.x,
      top: position.xs.y,
    },
    md: {
      right: position.md.x,
      top: position.md.y,
    },
  };
  if (isMobile) return positionToUse.xs;
  else return positionToUse.md;
};

export const officeSpaceSayScroll = (e) => {
  const officeSpace = document.querySelector('#office-space-left');
  const officeSpaceContainer = document.querySelector(
    '.office-space-container'
  );
  const containerTop = officeSpaceContainer?.getBoundingClientRect().top;
  if (containerTop <= 0 && containerTop >= -3964) {
    officeSpace?.classList.add('is-sticky-client-say');
    officeSpace?.classList.remove('office-space-container');
    officeSpace?.classList.remove('office-space-container-bottom');
  } else if (containerTop > 0) {
    officeSpace?.classList.remove('is-sticky-client-say');
    officeSpace?.classList.add('office-space-left-container');
    officeSpace?.classList.remove('office-space-container-bottom');
  } else if (containerTop < -3964) {
    officeSpace?.classList.remove('is-sticky-client-say');
    officeSpace?.classList.remove('office-space-left-container');
    officeSpace?.classList.add('office-space-container-bottom');
  }
};

export const isStickyNav = (e) => {
  const header = document.querySelector('.navbar-container');
  const loginBtn = document.querySelector('.login-nav-btn');
  const scrollTop = window.scrollY;
  if (scrollTop >= 100) {
    header?.classList.add('is-sticky');
    loginBtn?.classList.add('is-outlined-btn');
  } else {
    header?.classList.remove('is-sticky');
    loginBtn?.classList.remove('is-outlined-btn');
  }
};

export const autoScroll = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

export const additionalStepRender = (selectedPlan) => {
  // debugger;
  if (selectedPlan.additionalStep) {
    const tempFourSteps = [...foursteps];
    tempFourSteps.splice(1, 0, {
      title: 'Step 2',
      description: selectedPlan.additionalStepTitle,
      disabled: true,
    });
    return tempFourSteps;
  } else return threeSteps;
};

export const currencyFormatter = (value) =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const planPriceBasedUponState = (
  selectedState,
  selectedService,
  planName,
  statePricing,
  price,
  isNum
) => {
  if (statePricing) {
    let price = 0;
    selectedService.plans.map((plan) => {
      if (plan.name === planName) {
        if (plan.states) {
          return plan?.states?.map((state) => {
            if (state?.state?.name === selectedState?.state?.name) {
              price = isNum ? state?.price : currencyFormatter(state?.price);
            }
          });
        }
      }
    });
    return price;
  } else return price;
};

export const calculateTotal = (planPrice, addons) => {
  let total = planPrice;
  if (addons?.length > 0) {
    addons?.map((addOn) => {
      if (addOn.price) {
        total += addOn.price;
      }
    });
  }
  return total;
};

export const taxCalculator = (total, tax) => {
  return (tax / 100) * total;
};
export const grandTotalCalculator = (total, tax) => {
  return total + tax;
};

export const groupedArray = (arr) =>
  arr?.reduce((acc, obj) => {
    // Find the group by heading
    console.log('acc in helper', acc);
    let group = acc?.find((item) => item.title === obj.heading);

    if (!group) {
      // Create a new group if not found
      group = { title: obj.heading, data: [] };
      acc.push(group);
    }

    // Add the object to the `data` property of the group
    const { heading, ...rest } = obj;
    group?.data?.push(rest);

    return acc;
  }, []);

export const checkDefaultAddon = (currentId, summary) => {
  let temp = false;
  summary?.addons?.map((addOn) => {
    if (addOn.id === currentId) {
      temp = true;
    }
  });
  return temp;
};
