import React from "react";
import "./index.scss";
import { Grid } from "antd";
import DeskBar from "./DeskBar";
import MobileBar from "./MobileBar";
import { useMediaQuery } from "react-responsive";

const NavBar = () => {
  // const { useBreakpoint } = Grid;
  // const { xs } = useBreakpoint();
  const isMobileOrLaptop = useMediaQuery({ query: "(max-width: 1366px)" });
  // return <>{console.log("breakpoint", isTabletOrLaptop)}</>;
  return <>{isMobileOrLaptop ? <MobileBar /> : <DeskBar />}</>;
};

export default NavBar;
